@use '../../../variables'as c;

.search-dropdown-wrap {
    .search-dropdown {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &.static-width {
        .dropdown-menu {
            width: 220px;
        }
    }

    .dropdown-menu {
        min-width: 50px;
        max-width: 220px;
    }

    input.form-control {
        margin: 6px 0px;
    }

    .form-control {
        color: #6b767f;
        text-decoration: none;
        display: flex;

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        i {
            line-height: 1.5em;
        }

        i::before {
            vertical-align: middle;
        }
    }

    ul {
        max-height: 250px;
        overflow: auto;
        margin-bottom: 0px;
    }

    &.lang-dropdown-mob {
        max-width: 100px;
    }

    .multirow-item {
        user-select: none;
        overflow: hidden;


        .multirow-item-main {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .multirow-item-secondary {
            font-size: 12px;
            color: #6b767f;
            margin-left: 6px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &.prefix-right {
        .form-toggle-with-prefix-qp {
            flex-direction: row-reverse;
        }
    }
}