@use '../../variables'as c;

.main-header-bar {
    z-index: 12;
    position: sticky;
    flex-shrink: 0;
    top: 0;
    background: #fff;
    box-shadow: 0px 4px 14px rgba(136, 151, 183, 0.15);

    .new-badge {
        position: absolute;
        left: -16px;
        top: -16px;
        background: c.$success-color;
        color: #fff;
        z-index: 2;
        padding: 0.25em 0.4em;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 6px;
        transform: rotate(-7deg);
    }

    .red-dot {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 6px;
        right: -1px;
        bottom: -1px;
        background-color: c.$error-color;
    }

    .red-unread-count {
        position: absolute;
        width: 14px;
        height: 14px;
        border-radius: 7px;
        right: 12px;
        bottom: 2px;
        background-color: c.$error-color;
        color: #fff;
        text-align: center;
        line-height: 18px;
        font-size: 12px;
    }

    .user-name-header {
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
    }

    .current-site-name {
        font-size: 12px;
        color: #94a3c1;
        line-height: 16px;
    }

    .user-avatar {
        position: relative;
        width: 28px;
        height: 28px;
        background: linear-gradient(0deg, #DAE1EF, #DAE1EF), #C4C4C4;
        border-radius: 14px;
        color: c.$text-light-color;
        text-align: center;
        line-height: 28px;
        font-size: 12px;
        font-weight: 600;
    }

    .dr-toggle {
        text-decoration: none;
        color: c.$text-primary-color;
        cursor: pointer;


        &.away-dr-toggle {
            height: 36px;
            margin: auto 0px;
            line-height: 36px;
            background: #f5f6f8;
            border-radius: 6px;
            padding: 0px 16px;
            user-select: none;
            color: c.$text-light-color;

            &:hover {
                background: #d9e0eb;
            }

            .status-bubble {
                position: absolute;
                background-color: #45c4a0;
                bottom: -1px;
                right: -1px;
                width: 8px;
                height: 8px;
                border-radius: 50%;

                &.is-away {
                    background-color: #d4b20f;
                }
            }
        }

    }
}

.sites-switch {
    text-decoration: none;
    color: c.$text-primary-color;
    margin-right: 12px;


    .dr-down-separator {
        width: 100%;
        border-bottom: 1px solid #d8e1f0;
        margin: 8px 0px;
    }


    .dr-text-option {
        cursor: pointer;
        font-weight: 300;
        color: #212529;
        text-decoration: none;

        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
        }
    }

    .sites-wrap {
        max-height: 410px;
        overflow: auto;
    }

    .site-select {
        position: relative;
        padding: 6px 16px;
        white-space: nowrap;
        display: flex;

        span {
            flex: 1;
            line-height: 30px;
        }
    }
}

.header-icon {
    text-decoration: none;
    color: #9686f2;
}

.dropdown-menu {
    box-shadow: 2px 2px 5px 3px rgba(218, 225, 239, 0.8);
    border-radius: 6px;
    border: none;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    background-color: #9686f271;
}


.away-container {
    h6 {
        font-size: 14px;
        color: #65718a;
        font-weight: 600;
    }

    .break-end-info {
        font-size: 12px;
        text-align: center;
        color: #6b7993;
    }

    .btn.away-btn {
        height: 22px;
        line-height: 22px;
        padding: 0px 8px;
        border: none;
        background: #f5f6f8;
        color: #9686f2;
        font-size: 14px;

        &:hover {
            background: #d9e0eb;
        }
    }
}