@use '../../../variables'as c;

.askly-chip {
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 14px;
    margin: 3px 5px;
    color: c.$text-light-color;
    cursor: pointer;
    background: #fbfdff;
    border: 1px solid #DAE1F9;
    user-select: none;
    
    &:hover {
        background: #fff;
    }

    &.active {
        color: c.$success-color;
        border-color:  c.$success-color;
        background-color: #fff;
    }
}

.chips-description {
    font-size: 12px; 
    color: #42526e;
    font-style: italic;
    margin-bottom: 4px;
}

.add-tw-chip {
    background: none;
    font-size: 14px;
    min-width: 120px;
    position: relative;

    input {
        border: none;
        outline: none;
        width: 100%;
        padding: 6px 12px 6px 12px;
        line-height: 16px;
        color: #42526e;
        border-bottom: 1px solid #ECF3F9;
    } 
}