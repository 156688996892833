@use 'variables'as c;
$theme-colors: ("primary": c.$primary-color, "success":c.$success-color, "secondary":c.$secondary-color) !default;

$border-radius: 6px;
$border-width: 1px;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$headings-font-weight: 800;

$tooltip-arrow-color: #323232;
$tooltip-bg: #323232;
$tooltip-color:#e0e0e0;

$popover-border-color: #fff;

.general-success {
  color: c.$success-color;
}

.general-error {
  color: c.$error-color;
}

.form-label {
  color: c.$text-light-color;
  font-weight: 500;
  font-size: 14px;
}

.error-text-sm {
  height: 12px;
  font-size: 12px;
  margin-bottom: 0;
  color: c.$error-color;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.tour-tip {
  font-size: 14px;
  color: #656565;
}

.popover .arrow {
  visibility: hidden;
}

.popover {
  box-shadow: 0px 2px 4px rgba(136, 151, 183, 0.55);
  border: none;
  outline: none;
}

.popover .popover-body {
  border: none;
  outline: none;
}


@import "~bootstrap/scss/bootstrap";


.dropdown-menu {
  min-width: 50px;
  box-shadow: 0px 4px 14px #dae1ef;
  border: none !important;
}

.dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-primary {
  color: #fff;

  &:hover,
  &:disabled {
    color: #fff;
  }
}

.btn-outline-success,
.btn-outline-primary {
  &:hover,
  &:disabled {
    color: #fff;
  }
}

.btn-success {
  color: #fff;

  &:active,
  &:hover,
  &:disabled {
    color: #fff;
  }
}

input,
textarea {
  &::placeholder {
    color: c.$text-extra-light-color;
  }
}

.form-control {
  justify-content: center;
  display: block;
  width: 100%;
  height: auto;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 400;
  color: c.$text-light-color;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f5f6f8;
  border-radius: 6px;

  &::placeholder {
    color: #bdc8e0;
  }

  &:focus {
    border-color: #00a9f4;
    color: #323232;
    box-shadow: 0 0 0 0.2rem rgba(0, 169, 244, 0.22);
  }
}

textarea:focus {
  border-color: #00a9f4;
  box-shadow: 0 0 0 0.2rem rgba(0, 169, 244, 0.22);
}

.calendly-overlay .calendly-popup {
  height: 100%;
  max-height: none;
}