.html-editor {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;

    .html-editor-area {
        flex: 1;
        margin: 6px;
        overflow: auto;
        outline: none;
        min-height: 100px;
        max-height: 300px;
    }

    .menu-area {
        display: flex;
        align-items: center;
        padding: 6px;
        border-top: 1px solid #e0e0e0;

        background: #f5f6f8;
        color: #42526e;
    }
 
    .link-edit {
        position: absolute;
        display: flex;
        bottom: 0;
        left: 0;
        right: 0;
        height: 42px;
        background: #fff;
        border: 1px solid;
        z-index: 2;
    }

    .element-link {
        display: inline;
        position: relative;
      }
      
      .element-link .popup {
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        background-color: white;
        padding: 6px 10px;
        gap: 10px;
        border-radius: 6px;
        border: 1px solid lightgray;
        user-select: none;
      }
      
      .element-link .popup a {
        display: flex;
        align-items: center;
        gap: 5px;
        padding-right: 10px;
        border-right: 1px solid lightgrey;
      }
      
      .element-link .popup button {
        border: none;
        background: transparent;
      }
      
      .element-link .popup button:hover {
        color: rebeccapurple;
        cursor: pointer;
      }
      
 .link-popout {
        position: absolute;
        display: flex;
        right: 16px;
        left: 16px;
        bottom: 6px;
        background-color: #fff; 
        border-radius: 4px;
        padding: 12px 16px;
    
        box-shadow: 0px 4px 14px #dae1ef;
        font-size: 14px;
        z-index: 1001;
        overflow: hidden;
        display: flex;
        flex-direction: column;

      }
}