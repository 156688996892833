@use '../../variables'as c;


.qr-toggle {
    &.open {
        #qr-toggle-icon {
            color: #9686f2;
        }
    }
}

.qr-modal {
    position: absolute;
    right: 0;
    bottom: calc(100% + 12px);
    background-color: #fff;
    width: 480px;
    height: 340px;
    border-radius: 4px;

    box-shadow: 0px 4px 14px #dae1ef;
    font-size: 14px;
    z-index: 1001;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &.loading {
        height: 240px;
    }

    &.get-started {
        height: 240px;
        display: flex;
        flex-direction: column;

        .top-section {
            background-color: #f6f6f6;
            padding: 12px 32px;
            height: 162px;
            display: flex;

            .title-txt {
                font-size: 22px;
                font-weight: 600;
                margin-bottom: 6px;
                color: #2c2547;
            }

            .sub-txt {
                font-size: 14px;
                color: #60697b;
            }

            .illustration-wrap {
                position: relative;
                width: 45%;
                flex-shrink: 0;

                img {
                    width: 100%;
                    object-fit: contain;
                }
            }

        }

    }


    &.list-view {
        .title-txt {
            font-size: 16px;
            font-weight: 500;
            color: c.$text-light-color;
        }

        .no-qr-placeholder {
            font-size: 14px;
            color: c.$text-light-color;
            justify-content: center;
        }

        .qr-content {
            display: flex;
            overflow: auto;

            .no-found {
                margin: auto;
                font-size: 14px;
                color: c.$text-light-color;
            }

            .qr-row {
                padding: 4px 8px;
                border-radius: 6px;
                cursor: pointer;

                &:hover {
                    background-color: #F5F6F8;
                }

                .title {
                    font-weight: 600;

                    .missing {
                        font-weight: 400;
                        color: c.$text-light-color;
                        font-size: 12px;
                        line-height: 18px;
                    }
                }

                .value {
                    color: c.$text-light-color;
                }
            }
        }
    }

}