@use '../../../variables'as c;
.badge-dropdown {
    .badges-list {
        padding-top: 6px;

        .bi-badge {
            font-size: 14px;
            display: inline-block;
            padding: 0.2em 0.6em;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            border-radius: 0.3em;
            margin: 0.4em 0.2em;

            user-select: none;
            background-color: #ECF3F9;
            color: c.$text-primary-color;

            .bi-badge-close {
                display: inline-block;
                padding-left: 0.4em;
                cursor: pointer;

                &:hover {
                    color: #D80027;
                }
            }
        }
    }

    input.form-control {
        width: 85%;
        margin: 6px auto;
    }

    .form-control {
        color: #6b767f;
        text-decoration: none;

        i {
            line-height: 1.5em;
        }

        i::before {
            vertical-align: middle;
        }
    }

    ul {
        max-height: 250px;
        overflow: auto;
    }
}