@use '../../variables'as c;

.chat-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    .assignee {
        display: flex;
        border-bottom: 1px solid #DAE1EF;
        padding: 12px 18px;
        height: 52px;

        label {
            color: c.$text-light-color;
            font-weight: 600;
            line-height: 32px;
            font-size: 14px;
            margin-bottom: 0;
            margin-right: 6px;
        }

        .float-label {
            position: absolute;
            bottom: -10px;
            font-size: 12px;
            background: #f5f6f8;
            padding: 0px 6px;
            border-radius: 4px;
            user-select: none;

            &.left {
                left: -10px;
            }

            &.right {
                right: -10px;
            }
        }
    }

    .chat-content-wrap {
        flex-direction: row;
        width: 100%;
        flex: 1 1;
        overflow: hidden;
        display: flex;
        box-sizing: content-box;

        .is-writing {
            display: flex;
            color: #bdc8e0;
            font-size: 14px;
            padding-left: 12px;
        }

        .chat-scroll-wrap {
            display: flex;
            flex-direction: column;

            min-width: 0px;
            padding: 20px 20px 10px 20px;
            flex: 1;

            .chat-scroll {
                flex: 1;
                overflow: auto;
                display: flex;
                flex-direction: column;
                box-sizing: content-box;
                margin-right: -18px;
                padding-right: 18px;

                .chat-action {
                    display: flex;
                    flex-direction: row;

                    margin: 12px 0px;
                    color: c.$text-extra-light-color;
                    font-size: 12px;
                    text-align: center;

                    &:before,
                    &:after {
                        content: "";
                        flex: 1 1;
                        border-bottom: 1px solid #DAE1EF;
                        margin: auto 8px;
                    }
                }
            }
        }
    }

    .back-button {
        padding: 0;
        margin: 0;
        border: 0;
        background: none;
        font-size: 16px;
        line-height: 34px;
        font-weight: 500;

        i {
            color: rgba(37, 48, 80, 1);
        }
    }

    .reply-wrap {
        margin-top: 10px;
        padding-left: 10px;
        border-left: 2px solid c.$text-light-color;

        .reply-txt {
            font-size: 12px;
            color: c.$text-light-color;
        }
    }

    .edit-message-container {
        padding: 12px 16px;
        background: #f5f6f8;
        border-radius: 4px;

        textarea {
            min-height: 32px;
            max-height: 120px;
        }
    }
}

.mb-main-area {
    .chat-box {
        height: 100%;
        background: #fff;
        font-size: 14px;

        .chat-box .chat-bubble {
            font-size: 14px;
        }

        .chat-footer {
            margin: 6px -6px 18px -6px;
            box-shadow: 0px 4px 24px rgba(136, 151, 183, 0.2);
            font-size: 16px;

            &.dual-input .input-wrap textarea {
                line-height: 24px;
            }
        }


        .chat-scroll {
            padding-right: 0;
            padding-top: 0;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}

.lds-ring {
    display: none;
    position: absolute;
    width: 28px;
    height: 28px;
    right: calc(50% - 14px);
    top: calc(50% - 14px);
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 5px;
    border: 5px solid #45c4a0;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #45c4a0 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}