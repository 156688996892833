@use '../../variables'as c;

.welcome-modal {
    h2 {
        font-size: 28px;
        text-align: center;
        margin: 16px 40px 24px 40px;
    }

    .plain-btn {
        background: none;
        color: #9686f2;
        border: none;
        outline: none;
        box-shadow: none;
    }
}