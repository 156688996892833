@use '../../variables'as c;

$dark-text: #0e1111;
$inactive-text: #999;
$buttonmaincolor:#5933b0;

$main-color2: #402f5d;
$main-color:#5933b0;
$error-text: #E64747;
$primary-soft: #EDE3FF;
$base-color: #3a3837;

$google-color: #de5246;
$faceboook-color: #4267B2;

$dark-text: #0e1111;
$inactive-text: #999;
$error-text: #E64747;

.sign-up-view {
    .form-group {
        margin-bottom: 0;
    }

    .auth-form {
        text-align: center;

        .login-title {
            font-style: normal;
            font-weight: bold;
            font-size: 40px;
            letter-spacing: 0.005em;
            color: #1B2537;
        }

        .login-details {
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            color: #646A73;
        }

        .signup-confirm {
            font-weight: normal;
            font-size: 15px;
            line-height: 25px;
            text-align: center;
            color: #646A73;
        }

    }

    .df-btn {
        font-size: 16px;
        text-decoration: none;
        padding: 6px 16px;
        background-color: $main-color;
        border: 1px solid $main-color;
        border-radius: 4px;
        color: #fff;
        outline: none;
        cursor: pointer;

        -webkit-transition: all 100ms cubic-bezier(0.7, 0.01, 1, 1);
        -moz-transition: all 100ms cubic-bezier(0.7, 0.01, 1, 1);
        -o-transition: all 100ms cubic-bezier(0.7, 0.01, 1, 1);
        transition: all 100ms cubic-bezier(0.7, 0.01, 1, 1);
    }

    .df-btn:hover {
        background-color: #20CC82;
        border: 1px solid #20CC82;
    }

    .df-btn:focus {
        box-shadow: 0 0 0 3px #20cc8285;
    }

    .df-btn:disabled,
    .df-btn[disabled] {
        background-color: #bab6bf;
        border: 1px solid #bab6bf;
    }

    .df-btn-inverse {
        background: transparent;
        border: 1px solid #3a3837;
    }

    .df-btn-inverse:hover {
        color: #fff;
    }

    .auth-switch {
        background-color: #e7d9f7;
        border-color: #e7d9f7;
        color: #5f2db9;
    }

    .auth-switch:hover {
        background-color: #d7c7e8;
        border-color: #d7c7e8;
        color: #5f2db9;
    }

    .auth-switch:focus {
        box-shadow: 0 0 0 3px #5f2db985;
    }

    .forgot-password {
        color: #646A73;
        text-decoration: none;
        outline: none;
        font-size: 14px;
    }

    .password-requirements {
        font-size: 12px;
        color: $inactive-text;

        .error {
            color: $error-text;
        }
    }

    .general-error {
        height: 18px;
        font-size: 12px;
        color: $error-text;
    }

    .sidelines {
        text-align: center;
        border-bottom: 1px solid #cacaca;
        height: 0.5em;
        line-height: 1;
        font-size: 14px;
        color: #cacaca;
        margin-top: 22px;
    }

    .sidelines span {
        display: inline-block;
        background: #fff;
        padding: 0 0.25em;
    }


    .social-grid {
        margin-top: 22px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 14px;
        row-gap: 8px;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .google {
            background-color: #de5246;
            border: 1px solid #de5246;
            color: #fff;
            font-weight: 500;

            &.df-btn:hover {
                background-color: #d14a3d;
                border: 1px solid #d14a3d;
            }

            &.df-btn:focus {
                box-shadow: 0 0 0 3px #d14a3d85;
            }
        }

        .facebook {
            background-color: #1877F2;
            border: 1px solid #1877F2;
            color: #fff;
            font-weight: 500;

            &.df-btn:hover {
                background-color: #086aeb;
                border: 1px solid #086aeb;
            }

            &.df-btn:focus {
                box-shadow: 0 0 0 3px #086aeb85
            }
        }
    }

    .left-content {
        color: #fff;
        display: flex;
        width: calc(100% + 28px);
        height: 100%;
        flex-direction: column;
        position: relative;

        margin: 62px 82px;

        .top-logo {
            margin: 0;

            img {
                height: 48px;
                max-width: 200px;
            }
        }


        .illustration {
            margin: auto;
            height: 80%;
            width: 55%;
            object-fit: contain;
        }

        .left-body {
            flex-grow: 1;
            display: flex;
            padding-top: 75px;

            .text-wrap {
                z-index: 2;
                width: 45%;
            }

            img {
                z-index: 0;
            }
        }

        .left-txt-main {
            font-size: 36px;
            line-height: 1.2;
            font-weight: 800;
            color: #302A4A;
        }

        .left-txt-sub {
            font-size: 24px;
            line-height: 1.1;
            font-weight: 500;
            color: #646A73;
            margin-bottom: 18px;
        }
    }
}