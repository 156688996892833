@use '../../../variables'as c;


.html-editor-area-action {
    padding: 6px;
    color: c.$icon-light-color;
    cursor: pointer;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    &:hover {
        color: #9686f2;
        border-bottom-color: #9686f2;
    }

    &.is-open {
        color: #9686f2;
        border-bottom-color: #9686f2;
    }
}