$primary-color: #9686f2;
$primary-dark: #655C8D;
// 
$text-primary-color: #253050;
$text-secondary-color: #94a3c1;
$text-light-color: #42526e;
$text-extra-light-color: #bdc8e0;

$text-sub: #344563;
$icon-light-color: #b5bfd3;
$secondary-color: #42526e;
$success-color: #45c4a0;
$error-color: #E64747;

$light-blue: #f5f6f8;