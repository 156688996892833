@use '../../variables'as c;

.ask-sub-modal {
    .modal-content {
        padding: 62px 42px;
        color: #4a4e57;
        text-align: center;

        .modal-icon {
            background-color: #E64747;
            color: #fff;
        }

        .modal-title {
            font-size: 24px;
            line-height: 26px;
            color: #E64747;
            padding: 0 1rem;
            margin-bottom: 3px;

            .title-contrast {
                color: #00d59c;
            }
        }

        .modal-sub-title {
            font-size: 14px;
            color: #4a4e57;
            margin-bottom: 22px;
        }

        .modal-para-src {
            font-size: 12px;
            color: #4a4e57;
            margin-bottom: 22px;
            font-style: italic;
        }

        .txt-contrast {
            font-weight: bold;
            color: #9686f2;
        }

        .txt-bold-gray {
            font-weight: bold;
        }

        a,
        button {
            white-space: nowrap;
            border: 0;
            outline: 0;
            display: inline-block;
            height: 42px;
            line-height: 42px;
            padding: 0 24px;
            color: #fff;
            border-radius: 20px;
            border-top-left-radius: 4px;
            font-size: 15px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.025em;
            background-color: #00d59c;
            text-decoration: none;
            -webkit-transition: all 150ms ease;
            transition: all 150ms ease;
            min-width: 210px;

            &:focus {
                outline: none;
            }

        }



    }
}