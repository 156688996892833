@use '../../variables'as c;


.email-input-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;

    .email-creator-wrap {
        flex-grow: 1;
        background-color: #FFF;

        .subject-input {
            border: none;
            flex-grow: 1;
            outline: 0;
            padding: 0px 8px;
            color: #42526e;
            border: none;
        }

        .cc-buttons-wrap {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 600;
            color: #bdc8e0;
            margin-left: auto;

            .cc-button {
                padding: 0px 4px;
                cursor: pointer;

                &:hover {
                    color: #253050;
                }
            }
        }
    }

    .attachment-file {
        font-size: 12px;
        background: #f5f6f8;
        padding: 1px 4px;
        border-radius: 2px;
        margin: 2px 4px;
        color: rgb(66, 82, 110);
        cursor: pointer;
        
        .close-ico {
            margin-left: 4px 
        }
    }

        .send-area {
            border: none;
            outline: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 4px 0px;
            height: 38px;
            font-size: 14px;
            
            input[type='file'] {
                display: none;
            }

            .note-btn .cmn-thin-btn {
                background-color: #fef5ea;
                color: #7e776f;

                &:hover {
                    background-color: #f6ece1;
                }
            }
        

        .area-action {
            padding: 6px;
            color: c.$icon-light-color;
            cursor: pointer;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
    
            &:hover {
                color: #9686f2;
                border-bottom-color: #9686f2;
            }
    
            &.is-open {
                color: #9686f2;
                border-bottom-color: #9686f2;
            }
        }
    }

    .link-edit {
        position: absolute;
        display: flex;
        bottom: 0;
        left: 0;
        right: 0;
        height: 42px;
        background: #fff;
        border: 1px solid;
        z-index: 2;
    }

    .element-link {
        display: inline;
        position: relative;
      }
      
      .element-link .popup {
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        background-color: white;
        padding: 6px 10px;
        gap: 10px;
        border-radius: 6px;
        border: 1px solid lightgray;
        user-select: none;
      }
      
      .element-link .popup a {
        display: flex;
        align-items: center;
        gap: 5px;
        padding-right: 10px;
        border-right: 1px solid lightgrey;
      }
      
      .element-link .popup button {
        border: none;
        background: transparent;
      }
      
      .element-link .popup button:hover {
        color: rebeccapurple;
        cursor: pointer;
      }
      

      .link-popout {
        position: absolute;
        display: flex;
        right: 16px;
        left: 16px;
        bottom: 6px;
        background-color: #fff; 
        border-radius: 4px;
        padding: 12px 16px;
    
        box-shadow: 0px 4px 14px #dae1ef;
        font-size: 14px;
        z-index: 1001;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }
}
