@use '../../variables'as c;

.visited-pages-modal {
    .modal-content {
        max-height: 600px;
        max-width: 380px;
    }

    h2 {
        font-size: 28px;
        text-align: center;
        margin: 16px 40px 24px 40px;
    }

    .plain-btn {
        background: none;
        color: #9686f2;
        border: none;
        outline: none;
        box-shadow: none;
    }

    .locations-wrap {
        overflow: auto;

        .location-row {
            margin-bottom: 6px;

            .location-header {
                display: flex;
                color: #8f9dbb;
                font-size: 14px;
                line-height: 22px;

                .visit-page-title {
                    font-weight: 600;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    margin-right: 8px;
                }

                .visit-time {
                    color: #8f9dbb;
                    font-size: 12px;
                    flex-shrink: 0;

                }
            }

            .location-url {
                font-size: 12px;

                a {
                    text-decoration: underline;
                    color: #00a9f4;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    display: block;
                    white-space: nowrap;
                }
            }
        }
    }

}