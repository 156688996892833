@use '../../../variables'as c;

.tag-dropdown {
    padding: 10px 8px 0px 8px;

    .input-search {
        input {
            border: none;
            outline: none;
            width: 100%;
            border: 1px solid #ced4da;
            padding: 8px 12px 8px 32px;
            line-height: 16px;
            border-radius: 6px;
            border: 1px solid #ced4da;
            color: #6b767f;

            &:focus {
                color: #495057;
                background-color: #fff;
                border-color: #9c88d7;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(84, 56, 168, 0.25);
            }
        }

        &::before {
            position: absolute;
            font-family: "Font Awesome 5 Pro";
            font-weight: 500;
            content: "\f002";
            color: #6b767f;
            left: 12px;
            top: 7px;
        }

        &:focus-within::before {
            color: #9c88d7;
        }
    }

    .badges-list {
        padding-top: 6px;

        .bi-badge {
            font-size: 14px;
            display: inline-block;
            padding: 0.2em 0.6em;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            border-radius: 0.3em;
            margin: 0.4em 0.2em;

            user-select: none;
            background-color: #ECF3F9;
            color: c.$text-primary-color;

            .bi-badge-close {
                display: inline-block;
                padding-left: 0.4em;
                cursor: pointer;

                &:hover {
                    color: #D80027;
                }
            }
        }
    }

    input.form-control {
        width: 100%;
        margin: 6px auto;
    }

    .form-control {
        color: #6b767f;
        text-decoration: none;

        i {
            line-height: 1.5em;
        }

        i::before {
            vertical-align: middle;
        }
    }

    .dropdown-main-menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        max-height: 250px;
        overflow: auto;
    }
}