@use '../../variables'as c;

.content-card.setup-page {
    padding: 32px 86px;
    flex-direction: column;
    margin: auto;
    margin-top: 8%;
    overflow: unset;
    position: relative;
    min-width: 500px;
    max-width: 750px;


    .cancel-setup {
        position: absolute;
        left: 34px;
        top: 28px;
        color: #97a8c6;
        cursor: pointer;
    }

    .finish-setup {
        position: absolute;
        right: 34px;
        top: 28px;
        color: #97a8c6;
        cursor: pointer;
    }

    &.lg-setup {
        margin-top: 50px;
    }

    .setup-ico {
        position: absolute;
        top: -32px;
        left: calc(50% - 32px);
        height: 64px;
        width: 64px;
        background-color: #F6F8FF;
        text-align: center;
        border-radius: 50%;
        line-height: 64px;
        font-size: 24px;
        color: #9686f2;
    }

    h2 {
        text-align: center;
        margin-bottom: 18px;
        margin-top: 6px;
    }

    textarea {
        background-color: #f5f6f8;
        border: none;
        resize: none;
    }

    .skip-tour {
        background: none;
        color: #9686f2;
        border: none;
        outline: none;
        box-shadow: none;
    }

    .step-4-body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .guide-btns {
            margin-top: 18px;

            h5 {
                font-size: 16px;
            }
        }
    }
}