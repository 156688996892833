.email-chips-wrap {
    .input {
        width: 100%;
        height: 45px;
        padding: 0 1rem;
        margin-top: 1rem;
        box-sizing: border-box;
        font: inherit;
        border-radius: 0.2rem;
        border: 2px solid #d4d5d6;
        color: #565656;
        -webkit-appearance: none;
      }
      
      .input:focus {
        border-color: cornflowerblue;
        outline: none;
      }
      
      .input.has-error {
        border-color: tomato;
      }
      
      .error {
        margin: 0;
        font-size: 90%;
        color: tomato;
      }
      
      .tag-item {
        background-color: #d4d5d6;
        display: inline-block;
        font-size: 14px;
        border-radius: 30px;
        height: 30px;
        padding: 0 4px 0 1rem;
        display: inline-flex;
        align-items: center;
        margin: 0 0.3rem 0.3rem 0;
      }
      
      .tag-item > .button {
        background-color: white;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        font: inherit;
        margin-left: 10px;
        font-weight: bold;
        padding: 0;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
}