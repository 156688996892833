@use '../../variables'as c;

.guide-page {

    .tour-btn {
        background: none;
        border: none;
        outline: none;
        font-size: 18px;
        color: c.$text-light-color;
        height: 33px;
        margin-left: auto;
        margin-right: 115px;
        margin-top: 18px;
        margin-bottom: 18px;

        button {
            outline: none;
            border: none;
            background: #4BD1A0;
            color: #fff;
            padding: 2px 12px;
            border-radius: 16px;
            margin-left: 8px;
            cursor: pointer;
        }
    }

    iframe {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        padding: 12px 42px;
    }
}