@use '../../variables'as c;

.qr-managment-content-modal {

    .modal-content {
        height: 380px;
    }

    .modal-title {
        padding: 0;
    }

    .qr-nav {
        flex: 1;
        margin-top: 12px;
        min-height: 60px;
        display: flex;
        flex-direction: column;
        padding: 4px 10px;
        overflow: auto;

        .no-qr-placeholder {
            font-size: 14px;
            color: c.$text-light-color;
        }

        .qr-elem-row {
            border-radius: 6px;
            font-size: 14px;
            padding: 4px 8px;
            cursor: pointer;
            margin-bottom: 4px;


            .title {
                color: c.$text-light-color;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .sub-txt {
                color: c.$text-light-color;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            &:hover {
                background-color: #9686f220;
            }

        }
    }


    .qr-edit {
        flex: 1;
        margin-top: 12px;

        .form-control:disabled {
            background-color: #fff;
        }
    }

    .qr-lang-select-lable {
        font-size: 12px;
        color: c.$text-light-color;
        margin-bottom: 8px;
    }

    .qr-selectable-lang {
        padding: 2px 6px;
        border-radius: 4px;
        font-size: 12px;
        margin: 2px 4px;
        color: c.$text-light-color;
        cursor: pointer;
        background: #fbfdff;

        &:hover {
            background: #eef4fa;
        }

        &.active {
            color: #fff;
            background: #9686f2;
        }
    }
}