@use '../../variables'as c;

.chat-list {
    width: 300px;
    background: #F5F6F8;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;


    .chat-list-scroll {
        height: 100%;
        overflow: scroll;

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .chat-list-elem {
        display: flex;
        flex-direction: column;
        color: c.$text-light-color;
        font-size: 12px;
        text-align: left;
        padding: 14px 12px 7px 6px;
        cursor: pointer;
        border-left: 6px solid transparent;


        &:hover {
            background-color: #eff0f2;
            border-left: 6px solid #9686f220;
        }

        &.active {
            background-color: #9686f220;
            border-left: 6px solid #9686f2;
        }


        .chat-list-elem-core {
            flex: 1;
            min-width: 0px;
        }

        .last-message {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            box-orient: vertical;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            position: relative;
            min-height: 18px;
        }

        .visitor-icon {
            width: 30px;
            height: 30px;
            border-radius: 15px;
            background: linear-gradient(0deg, #DAE1EF, #DAE1EF), #C4C4C4;
            margin-right: 12px;
            color: #2b364c;
            text-align: center;
            line-height: 30px;
            font-size: 12px;
            position: relative;
            flex-shrink: 0;


            .visitor-letter {
                text-transform: uppercase;
            }

            .is-online {
                position: absolute;
                background-color: #45c4a0;
                right: 1px;
                top: 1px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
            }

            .email-icon {
                fill: hsl(220, 82%, 67%);
                color: #6296fd;
                position: absolute;
                width: 14px;
                height: 14px;
                right: -3px;
                bottom: -3px;
            }

            .insta-icon {
                position: absolute;
                width: 14px;
                height: 14px;
                right: -3px;
                bottom: -3px;
            }

            .fb-icon {
                position: absolute;
                width: 18px;
                height: 18px;
                right: -4px;
                bottom: -4px;
            }
        }

        .visitor-name {
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: c.$text-primary-color;
            padding-right: 20px;
        }

        .time {
            font-size: 10px;
            text-align: right;
            color: c.$text-light-color;
            font-weight: 400;
        }

        .unread {
            background-color: #E64747;
            font-size: 11px;
            line-height: 18px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            text-align: center;
            color: #fff;
            position: absolute;
            right: 0;
            top: 1px;
        }
    }

    .chat-type-switch {
        margin: 12px 0px;
        user-select: none;
        text-align: center;

        .switch-container {
            background: #fff;
            height: 34px;
            padding: 3px;
            border-radius: 17px;
            line-height: 28px;
            display: inline-block;
            font-weight: 600;
            font-size: 14px;
            color: rgba(37, 48, 80, 1);

            .choice {
                padding: 0px 32px 0px 12px;
                position: relative;
                cursor: pointer;
                display: inline-block;

                &.archive {
                    padding: 0px 12px 0px 12px;
                }

                .counter {
                    position: absolute;
                    right: 6px;
                    top: 6px;
                    background: #DAE1EF;
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    font-size: 12px;
                    border-radius: 9px;
                    color: #fff;
                }

                &.active {
                    background: #9686f2;
                    border-radius: 14px;
                    color: #fff;

                    .counter {
                        background: #9686f2;
                    }
                }
            }
        }
    }
}

.chat-box {

    .chat-header {
        height: 58px;
        padding: 12px 22px;
        border-bottom: 1px solid #dae3f1;
        margin: 0px -18px;
        font-size: 14px;
        line-height: 34px;

        .chat-language {
            color: rgb(37, 48, 80);
            cursor: pointer;

            &:hover {
                color: #9686f2;
            }

            &.dropdown-toggle::after {
                vertical-align: 0.155em;
            }
        }

        .status-live {
            background: rgba(142, 194, 0, 0.1);
            color: rgb(142, 194, 0);
            padding: 0px 8px;
            border-radius: 6px;
            font-size: 14px;
            line-height: 22px;
            margin: 6px;
            text-transform: uppercase;

            &:before {
                content: "\A";
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: rgb(142, 194, 0);
                display: inline-block;
                margin: 2px 4px 2px 0px;
            }
        }

        i {
            font-size: 16px;
            line-height: 34px;
            color: #9686f2;
        }
    }


    .start-conversation-wrap {
        display: flex;
        margin: 18px 0px;
        flex-direction: column;
        color: #DAE1EF;

        .extra-actions {
            display: flex;
            margin-bottom: 32px;
            font-size: 14px;

            .visitor-lang-wrap {
                cursor: pointer;
                border-radius: 4px;
                padding: 3px 6px;
                background-color: #F5F6F8;
                color: c.$text-light-color;
                //box-shadow: 0px 4px 24px rgba(218, 225, 239, 0.5);
            }

            .archive-chat {
                cursor: pointer;
                border-radius: 4px;
                padding: 3px 6px;
                background-color: #F5F6F8;
                color: c.$text-light-color;
                //box-shadow: 0px 4px 24px rgba(218, 225, 239, 0.5);
            }
        }

        .language-select-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;

            .language-select {
                display: flex;
                padding: 3px 6px;
                //box-shadow: 0px 4px 24px rgba(218, 225, 239, 0.5);
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                .lang-option {
                    line-height: 22px;
                    border-radius: 6px;
                    margin: 0px 8px;
                    padding: 4px 12px;
                    box-shadow: 0px 4px 24px rgba(218, 225, 239, 0.5);
                    cursor: pointer;
                    color: c.$text-light-color;
                    font-weight: 600;
                    font-size: 14px;
                    text-align: center;
                    border: 1px solid #fff;

                    &:hover {
                        background: #9686f2;
                        color: #fff;
                        box-shadow: 0px 4px 48px rgba(218, 225, 239, 0.8);
                    }

                    &.visitor-lang {
                        border: 1px solid #9686f2;
                    }
                }
            }
        }
    }

    .start-conversation {
        display: flex;
        margin: 12px 0px;

        .lang-option {
            height: 42px;
            line-height: 42px;
            border-radius: 6px;
            margin: 0px 8px;
            box-shadow: 0px 4px 24px rgba(218, 225, 239, 0.5);
            cursor: pointer;
            color: #9686f2;
            font-weight: 600;
            font-size: 16px;
            width: 100%;
            text-align: center;

            &:hover {
                background: #9686f2;
                color: #fff;
                box-shadow: 0px 4px 48px rgba(218, 225, 239, 0.8);
            }

            &.visitor-lang {
                line-height: 40px;
                border: 1px solid #9686f2;
            }
        }
    }

    .notes-wrap {
        flex: 1;
        background: #fff5ea;
        color: #42516e;

        textarea {
            background: inherit;
            color: inherit;
            width: 100%;
            height: 100%;
            margin: 0;

            ::placeholder {
                color: #dcdddf;
            }
        }

    }



    .chat-footer.dual-input {
        .input-wrap {
            display: flex;
            flex-direction: column;
            flex: 1;

            .translation-wrap:hover {
                .edit-translation {
                    opacity: 1;
                }
            }

            .translation-wrap {
                position: relative;
                display: flex;
                width: 100%;
                background-color: #F5F6F8;

                .edit-translation {
                    opacity: 0;
                    position: absolute;
                    inset: 0;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #dae1ef80;
                    color: #59667f;
                    pointer-events: none;
                }
            }

            .language-input {
                display: flex;

                .language-switch {
                    width: 52px;
                    color: c.$text-primary-color;
                    font-size: 13px;
                    text-align: center;
                    line-height: 34px;
                    text-transform: uppercase;
                    border-right: 1px solid #DAE1EF;
                    flex-shrink: 0;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .lang-text {
                        margin-left: 4px;
                        color: #302a4a;
                        font-weight: 500;
                        font-size: 11px;

                    }

                }

            }

            :first-child.language-input {
                border-bottom: 1px solid #DAE1EF;
            }

            textarea {
                max-height: 180px;
                line-height: 18px;
                overflow: auto;
                resize: none;
                border: none;
                padding: 8px 12px;
                outline: none;
                flex: 1;
            }
        }
    }

    .chat-footer {
        position: relative;
        min-height: 42px;
        margin-top: 18px;
        display: flex;
        flex-direction: column;

        .input-wrap {
            display: flex;
            flex: 1;
        }

        &.sending {
            pointer-events: none;

            .lds-ring {
                display: block;
            }

            .cmn-thin-btn.primary {
                background: #9686f266;
            }

            .send-area .area-action {
                color: #ecf3f9;
            }
        }

        .emoji-picker-popup-wrap {
            position: absolute;
            top: 0;
            right: 0;

            em-emoji-picker {
                position: absolute;
                bottom: 12px;
                right: 0px;

                --border-radius: 8px;
                --rgb-accent: 150, 134, 242;
            }
        }

        .selected-files {
            border-bottom: 1px solid #DAE1EF;
            font-size: 12px;
            padding: 6px 12px;
            color: #253150;
            background: #F0F4FE;
            line-height: 18px;

            span {
                padding: 0px 4px;
                margin-right: 6px;
                display: inline-block;
                cursor: pointer;

                .file-ico {
                    margin-right: 4px;
                }

                .close-ico {
                    margin-left: 4px;
                }

                .fa-file-alt {
                    color: #2D77F6;
                }

                .fa-file-image {
                    color: #9686f2;
                }

                &:hover {
                    .close-ico {
                        color: #D80027;
                    }
                }
            }
        }

        form {
            display: flex;
            width: 100%;
            min-height: 42px;
            box-shadow: 0px 4px 24px rgba(218, 225, 239, 0.5);
        }

        .send-area {
            border: none;
            outline: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 4px 0px;
            height: 38px;

            input[type='file'] {
                display: none;
            }

            .note-btn .cmn-thin-btn {
                background-color: #fef5ea;
                color: #7e776f;

                &:hover {
                    background-color: #f6ece1;
                }
            }

            .area-action {
                padding: 6px;
                color: c.$icon-light-color;
                cursor: pointer;
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent;

                &:hover {
                    color: #9686f2;
                    border-bottom-color: #9686f2;
                }

                &.is-open {
                    color: #9686f2;
                    border-bottom-color: #9686f2;
                }
            }

            .cmn-thin-btn {
                .send-text-area {
                    display: flex;
                }

                i {
                    color: inherit;
                    margin: auto;
                    font-size: 12px;
                    margin-left: 4px;
                    padding: 0;
                }
            }
        }
    }


    .chat-footer textarea {
        max-height: 180px;
        line-height: 25px;
        overflow: auto;
        resize: none;
        border: none;
        padding: 8px 12px;
        outline: none;
        flex: 1;
        border: none;
        color: c.$text-primary-color;

        &:focus {
            z-index: 2;
        }
    }

    .note-bubble-container {
        padding: 12px 14px;
        background: #fff5ea;
        color: #42516e;
        border-radius: 8px;
        margin: auto;
        text-align: right;
        font-size: 12px;
    }

    .chat-bubble-container {
        display: grid;
    }

    .image-bubble {
        background: transparent !important;
        padding: 0px !important;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 16px !important;
        cursor: pointer;

        .download-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 16px;
            display: none;

            i {
                margin: auto !important;
                font-size: 32px !important;
            }
        }

        &:hover {
            .download-overlay {
                display: flex;
            }
        }

        img {
            vertical-align: middle;
            border-style: none;
            width: 80px;
            height: 80px;
            object-fit: cover;
            display: flex;
            border-radius: 16px;
        }

        video {
            vertical-align: middle;
            border-style: none;
            max-width: 120px;
            max-height: 210px;
            pointer-events: none;
            object-fit: cover;
            border-radius: 16px;

            &::-webkit-media-controls-panel {
                display: none !important;
                opacity: 1 !important;
            }

        }
    }

    a.chat-bubble {
        font-size: 12px;
        line-height: 25px;
        text-decoration: none;
        padding: 9px 14px;
        display: flex;

        .file-ico {
            margin-right: 8px;
            background: #ffffff;
            border-radius: 4px;
            width: 24px;
            height: 24px;
            line-height: 25px;
            text-align: center;
            font-size: 15px;
            flex-shrink: 0;

            &.fa-file-alt {
                color: #2D77F6;
            }

            &.fa-file-image {
                color: #9686f2;
            }
        }

        .download {
            line-height: 24px;
            font-size: 15px;
            margin-left: 22px;
            flex-shrink: 0;
        }

        .timestamp {
            bottom: -20px;
        }
    }

    .chat-bubble {
        background: #ECF3F9;
        margin: 22px 0px;
        border-radius: 8px 20px 20px 20px;
        color: rgba(37, 48, 80, 1);
        max-width: 400px;
        min-width: 65px;
        font-size: 12px;
        position: relative;
        text-align: left;
        margin-right: auto;
        display: inline-block;
        font-weight: 500;

        a {
            color: inherit;
            text-decoration: underline;
        }

        .chat-content {
            padding: 12px 14px;

            span {
                word-wrap: break-word;
                white-space: pre-wrap;
            }
        }

        .chat-attachment {
            background: #DAE7F2;
            border-radius: 0px 0px 20px 20px;
            overflow: hidden;

            .chat-translation-attachment {
                padding: 8px 14px;
                white-space: pre-wrap;
                word-wrap: break-word;
            }
        }

        .timestamp {
            color: c.$text-extra-light-color;
            position: absolute;
            bottom: -16px;
            left: 6px;
            user-select: none;
            font-size: 10px;
            min-width: 75px;
        }

        .support-name {
            //color: c.$text-primary-color;
            color: c.$text-extra-light-color;
            font-weight: 500;
            position: absolute;
            top: -16px;
            right: 6px;
            user-select: none;
            font-size: 10px;
            min-width: 75px;
            line-height: 15px;
            white-space: nowrap !important;
        }

        .chat-options {
            display: flex;
            opacity: 0;
            position: absolute;
            color: c.$text-light-color;
            height: 100%;
            top: 0px;
            right: -18px;
            width: 18px;
            padding-left: 6px;

            transition: opacity .25s ease-out;
            -moz-transition: opacity .25s ease-out;
            -webkit-transition: opacity .25s ease-out;

            .quick-reply-shortcut {
                margin: auto 4px;
                cursor: pointer;

                &:hover {
                    color: #9686f2;
                }
            }

            .cst-dropdown-btn {
                width: 12px;
                text-align: center;
                cursor: pointer;
            }

            &.open {
                opacity: 1;
            }

            .dropdown-menu {
                box-shadow: 2px 2px 5px 3px rgba(218, 225, 239, 0.8);
                border-radius: 6px;
                border: none;
                min-width: 7rem;
            }

            .option-item {
                cursor: pointer;
                font-size: 12px;
                text-decoration: none;
                text-align: left;
            }
        }

        &:hover {
            .chat-options {
                opacity: 1;
                transition: opacity 0.15s ease-out;
                -moz-transition: opacity 0.15s ease-out;
                -webkit-transition: opacity 0.15s ease-out;
            }
        }

        &.opposite {
            background: #302A43;
            color: #fff;
            border-radius: 20px 8px 20px 20px;
            margin-left: auto;
            text-align: right;
            margin-right: 0;

            .chat-options {
                left: -18px;
                right: auto;
                padding-right: 6px;
                padding-left: 0px;
                flex-direction: row-reverse;
            }

            .chat-attachment {
                background: #403960;
            }

            .timestamp {
                right: 6px;
                left: auto;
                white-space: nowrap;
            }
        }

        &.chat-input-bubble {
            background: #F5F6FA;

            .chat-input {
                padding: 12px 18px;
                border-radius: 20px;
                position: relative;

                .response {
                    border: none;
                    outline: none;
                    height: 36px;
                    min-width: 140px;
                    max-width: 540px;
                    font-size: 16px;
                    color: c.$text-light-color;
                    border-radius: 21px;
                    padding: 2px 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    background: #fff;
                    line-height: 32px;
                }

                .chat-input-btn {
                    position: absolute;
                    right: 26px;
                    top: 20px;
                    outline: none;
                    border: none;
                    border-radius: 6px;
                    padding: 4px 10px;
                    background: #9686f2;
                    color: #fff;
                    font-size: 12px;
                }
            }
        }
    }
}

.chat-ph {
    display: flex;
    flex-direction: column;
    padding: 0px 18px;
    justify-content: center;
    flex-grow: 1;

    figure.no-select {
        position: sticky;
        top: 60px;

        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        text-align: center;

        img {
            height: 360px;
            width: 360px;
            margin: 0px auto;
            object-fit: contain;
        }

        figcaption {
            margin: auto;
            color: #332b4d;
            max-width: 880px;
            font-size: 14px;

            .small-title {
                font-weight: 600;
                font-size: 32px;
                line-height: 32px;
            }

            p {
                margin-top: 6px;
            }
        }
    }
}

.mb-main-area {
    .chat-list {
        height: 100%;
        width: 100%;
        border-right: none;
        padding: 6px 14px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .chat-list-scroll {
            height: 100%;
            width: 100%;

            .chat-list-elem {
                border-bottom: none;
            }
        }

        .chat-list-elem-core {
            max-width: 100%;

            .last-message {
                max-width: calc(100vw - 100px);
            }
        }

        .chat-type-switch {
            /*position: sticky;
            top: 0;*/

            .switch-container {
                font-size: 18px;
                line-height: 32px;
                height: 38px;
                border-radius: 19px;

                .choice.active {
                    border-radius: 16px;
                }

                .counter {
                    right: 7px;
                    top: 7px;
                }
            }
        }
    }
}