@use '../../variables'as c;


.add-email-domain-modal {
    .modal-title {
        font-size: 23px;
        line-height: 37px;
        color: #1b2537;
        padding: 0 1rem;
    }

    .footnote {
        color: #646a73;
        padding: 0 1rem;
    }

    .input-container {
        position: relative;
        padding: 0 1rem;

        input {
            width: 100%;
            height: 56px;
            background: #f2f4f7;
            border-radius: 8px 0 0 0;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 26px;
            position: relative;
            border: none;
            border-bottom: solid 2px #7D55C8;
            outline: 0;
            padding: 26px 16px 4px;
        }

        .hint {
            position: absolute;
            left: 32px;
            top: 4px;
            color: #7D55C8;
        }

        .dropdown {
            height: 56px;
            background: #f2f4f7;
            border-radius: 0 8px 0 0;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 26px;
            position: relative;
            border: none;
            border-bottom: solid 2px #7D55C8;

            button {
                height: 56px;
                color: #646a73;
                background: transparent;
                border: none;
                border-radius: 0;
                outline: none;
                box-shadow: none !important;

                &:active {
                    color: #7D55C8 !important;
                    background: transparent !important;
                }
            }

            .dropdown-toggle::after {
                margin-left: 0.455em;
            }
        }

        .dropdown .dropdown-menu {
            top: 55px;
            border: none;
            width: 100%;
            box-shadow: 0px 8px 24px rgba(27, 37, 55, 0.08);
            padding: 0;
        }

        .dropdown .dropdown-menu .dropdown-item {
            height: 50px;
            box-sizing: border-box;
            background-color: #fcfdff;
            border-bottom: solid 1px #f2f4f7;
            display: flex;
            align-items: center;
            text-align: center;
            padding: 0 20px;

            &:hover {
                text-decoration: none;
                background-color: #e5ddf4;
            }
        }

        .dropdown .dropdown-menu .dropdown-item:last-child {
            border-bottom: none;
        }
    }

    .row.user {
        margin-bottom: 30px;
        width: 100%;
    }

    .row.user .username {
        display: flex;
        align-items: center;
    }

    .row.user .username .full-name {
        margin-bottom: 0px;

        &.pending {
            color: #9ca5af;
        }

        .member {
            font-size: 12px;
            line-height: 18px;
            color: #9ca5af;
            margin-left: 6px;
        }
    }

    .row.user .username .user-email {
        font-size: 12px;
        line-height: 18px;
        color: #9ca5af;
    }

    .row.user .avatar {
        height: 38px;
        width: 38px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #f2f4f7;
        color: #7D55C8;
        font-size: 14px;
        text-transform: uppercase;
        margin-right: 14px;
    }

    .row.user .avatar img {
        width: 100%;
        height: 100%;
    }

    .row.user .role {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}