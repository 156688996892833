@use '../../../variables'as c;

.volume-slider {
    i {
        padding: 2px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        text-align: center;

        &:hover {
            color: #9686f2;
        }
    }

    input[type="range"] {
        // -webkit-appearance: none;
        width: 120px;
        accent-color: #9686f2;
        //background: rgba(255, 255, 255, 0.6);
        // background-repeat: no-repeat;
        //  border: none;
        //  border-radius: 3px;
    }
}