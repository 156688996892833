@use '../../variables'as c;

.main-side-bar-wrap {
    padding: 24px 12px 24px 0px;
    height: inherit;
    //position: fixed;

    .red-dot {
        position: absolute;
        padding: 0px 3px;
        height: 12px;
        min-width: 12px;
        border-radius: 6px;
        right: 2px;
        top: 2px;
        border-radius: 6px;
        background-color: c.$error-color;
        font-size: 10px;
        line-height: 12px;
        color: #fff;
    }

    .main-side-bar {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 4px 24px rgba(141, 145, 156, 0.1);
        height: 100%;
        width: 66px;
        padding: 16px;
        z-index: 1;
        background: #fff;
        border-radius: 0px 12px 12px 0px;
        transition: width 0.10s ease-out;
        flex-direction: row;

        &.wide {
            width: 280px;
        }

        .quickstart-ico {
            cursor: pointer;
            margin-top: 24px;
            color: c.$text-light-color;
            user-select: none;
            width: 36px;
            text-align: center;
            font-size: 12px;

            display: flex;
            flex-direction: column;
            align-items: center;

            &.active,
            &:hover {
                i {
                    color: #9686f2;
                }
            }

            i {
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
            }


            .load-steps {
                width: 100%;
                background: #ffcc0047;
                border-radius: 2px;
                position: relative;
                color: #fa9f47;
            }
        }

        .quickstart-wrap {
            padding-left: 24px;
            padding-top: 24px;
            margin-left: 12px;
            border-left: 1px solid #eaf1ff;
            flex-grow: 1;
            //overflow: hidden;
            font-size: 14px;
            position: relative;
            display: flex;
            opacity: 0;
            user-select: none;
            pointer-events: none;

            &.open {
                flex-direction: column;
                opacity: 1;
                transition: opacity 0.25s 0.1s ease-out;
                user-select: auto;
                pointer-events: all;
            }

            .ignore-qs {
                color: c.$text-light-color;
                cursor: pointer;
            }

            .qs-text-wrap {
                font-size: 14px;
                color: c.$text-light-color;
            }

            .qs-title-wrap {
                display: flex;


                .qs-title-text-wrap {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;

                    .qs-title-text-title {
                        font-size: 18px;
                        font-weight: 600;
                    }

                    .qs-title-text-sub {
                        font-size: 14px;
                        color: c.$text-light-color;
                    }
                }

                .actions {
                    position: absolute;
                    cursor: pointer;
                    top: 0px;
                    right: 6px;
                    font-size: 12;
                    color: c.$text-light-color;
                }
            }

            .qs-step {
                // border: 1px solid #DAE1EF;
                padding: 2px 0px;
                border-radius: 6px;
                //box-shadow: 0px 4px 6px 2px rgba(141, 145, 156, 0.1);
                margin-bottom: 12px;
                font-size: 14px;


                .qs-step-title {
                    font-weight: 500;
                    padding: 4px 8px;
                    color: c.$text-light-color;
                    border-radius: 6px;
                    margin-bottom: 3px;
                    border: 1px solid c.$text-light-color;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    text-decoration: none;

                    i {
                        font-size: 14px;
                        color: c.$text-light-color;
                        margin-right: 6px;
                    }

                }

                .qs-step-sub {
                    color: c.$text-light-color;
                    font-size: 12px;
                }

                .qs-step-action {
                    display: none;
                    padding: 4px 12px;
                    background-color: #9686f2;
                    text-align: center;
                    color: #fff;
                }


                &.done {
                    .qs-step-title {
                        text-decoration: line-through;
                        color: rgb(142, 194, 0);

                        i {
                            color: rgb(142, 194, 0)
                        }
                    }
                }
            }
        }

        img {
            -moz-transform: scaleX(-1, );
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);

            align-self: center;
            width: 38px;
            height: 38px;
        }

        .side-bar-inner {
            display: flex;
            flex-direction: column;
            margin: 0px auto;
            margin-top: 30px;
        }

        .side-bar-link {
            position: relative;
            user-select: none;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            width: 36px;
            height: 36px;
            margin-bottom: 12px;
            text-align: center;

            border-radius: 6px;
            color: c.$text-light-color;
            transition: all .1s ease-in-out;

            &:hover {
                background: #f6f6f7;
                box-shadow: 0px 0px 5px 2px #9686f220;
                color: #9686f2;
            }

            &.active {
                background: #9686f220;
                color: #9686f2;
            }

            &:active {
                transition: background .1s;
            }
        }
    }
}