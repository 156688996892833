.df-page {
    width: 100%;
    margin-left: 66px;

    .df-page-main-container-scroll {
        width: 100%;
        display: flex;
        min-height: calc(100vh - 64px);
    }

    .df-page-main-container {
        width: 100%;
        display: flex;
        height: calc(100vh - 64px);
    }
}