@use '../../variables'as c;

.chat-full-preview {
    display: flex;
    flex-direction: column;

    &.v-dark {
        .widget-navs {
            color: #000;

            .widget-tab-switch {
                &.selected {
                    background: #000;
                }
            }
        }

        .chat-area {
            position: relative;
            .written-at,
            .visitor-bubble {
                color: #000;
            }
        }

        .tab-body-icon-wrap i {
            color: #000;
        }
    }

    .widget-navs {
        position: relative;
        display: flex;
        padding: 8px 12px;
        color: #fff;
        width: 100%;
        height: 43px;
        box-sizing: border-box;
        align-items: center;

        // background: url(/widget/static/media/background-pattern_1.9b38f301.svg) no-repeat, linear-gradient(357.81deg, var(--main-theme-color) 1.37%, var(--main-theme-color-lighter) 189.34%);
        background-size: 100% auto;

        .widget-tab-switch {
            background: var(--main-theme-color-lighter);
            border-radius: 16px;
            font-size: 16px;
            height: 32px;
            width: 32px;
            padding: 0px;
            margin: 0px 12px;
            align-items: center;
            display: flex;
            justify-content: center;
            cursor: pointer;

            &.selected {
                background: #fff;
                color: var(--main-theme-color);
            }

            i {
                margin: auto;
            }
        }
    }

    .widget-content {
        // height: 100%;
        flex: 1;
    }

    .chat-tab {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .support-ico {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;
        color: #27304E;
        display: flex;

        object-fit: cover;

        &.lg {
            width: 34px;
            height: 34px;
            font-size: 20px;
        }

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    .widget-top-area {
        position: sticky;
        top: 0px;
        
        width: 350px;
        height: 460px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0px 4px 24px rgba(136, 151, 183, 0.14);
        border-radius: 12px;
        overflow: hidden;
    }


    .chat-tab {
        .gray-header {
            background-color: #f5f6fa;
            box-sizing: border-box;
            padding: 0px 18px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            position: relative;
            min-height: 36px;
            width: 100%;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            padding: 0 22px;
            -webkit-flex-direction: row-reverse;
            -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            font-size: 12px;

            .powered-by {
                display: flex;
                align-items: center;
                font-size: 10px;
                color: c.$text-light-color;

                img {
                    height: 12px;
                    margin-left: 6px;
                }
            }
        }

        .chat-header {
            display: flex;
            padding: 8px 14px 11px 14px;
            margin-bottom: 10px;
            position: relative;
            z-index: 3;
            padding: 11px 14px 11px 14px;
            width: 100%;
            box-sizing: border-box;
            align-items: center;

            .support-details {
                display: flex;
                flex-direction: column;
                font-size: 14px;
                margin-left: 6px;

                .open-hours {
                    color: c.$text-light-color;
                    font-size: 12px;
                    line-height: 12px;

                    &:before {
                        content: "";
                        width: 10px;
                        min-width: 10px;
                        height: 10px;
                        margin-right: 4px;
                        display: inline-block;
                        background: url(../../resources/time.svg) center center no-repeat transparent;
                    }
                }
            }
        }
    }

    .chat-area {
        padding: 6px 12px;
        display: flex;
        flex-direction: column;
        flex: 1;

        &.test-conversation {
            padding: 6px 0px 6px 12px;
            .messages-wrap {
                padding-right: 12px;
            }
        }
        .messages-wrap {
            position: relative;
            overflow: scroll;
            height: 300px;
            padding-top: 12px;
            padding-bottom: 12px;
            font-size: 14px;
        }

        .pii-notification {
                display: flex;
                flex-direction: row;
                margin-bottom: 12px;
                color: rgb(136, 151, 183);
                font-size: 12px;
                text-align: center;
                span {
                    max-width: 70%;
                }
                &:before,
                &:after {
                    content: "";
                    flex: 1 1;
                    border-bottom: 1px solid #DAE1EF;
                    margin: auto 8px;
                }
        }

        .input-area {
            margin-top: auto;
            position: relative;

            input {
                height: 40px;
                border-radius: 28px;
                outline: none;
                padding: 0 14px;
                padding-right: 52px;
                border: 1px solid #f0f4fe;
                font-size: 16px;
                line-height: 18px;
                width: 100%;
                box-sizing: border-box;
                margin-top: auto;
                -webkit-appearance: none;
                box-shadow: none;
                border-radius: 28px;
                margin-bottom: 6px;
            }

            .send-input {
                width: 30px;
                height: 30px;
                background: transparent url(../../resources/send-button.svg) no-repeat center;
                position: absolute;
                top: 5px;
                right: 12px;
                border: none;
                cursor: pointer;
                visibility: visible;
            }

        }

        .written-at {
            font-size: 10px;
            color: #fff;
            margin: 0;
        }

        .visitor-bubble {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 6px 12px;
            background: #ECF3F9;
            color: #fff;
            margin-left: auto;
            margin-right: 0;
            margin-bottom: 12px;
            border-radius: 12px;
            border-top-right-radius: 5px;

        }

        .support-message {
            display: flex;

            .support-bubble {
                margin-left: 6px;
                background: #ECF3F9;
                border-radius: 12px;
                border-top-left-radius: 5px;
                overflow: hidden;
                margin-bottom: 12px;


                .message {
                    position: relative;
                    padding: 6px 12px;
                }

                .translation {
                    padding: 6px 12px;
                    background: #DAE7F2;
                }
            }
        }

        .is-writing {
            position: absolute;
            bottom: 92px;
            display: flex;
            color: #bdc8e0;
            font-size: 14px;
            padding-left: 12px;
        }
    }

    .call-us-tab {

        .title {
            font-size: 20px;
            line-height: 26px;
            font-weight: 500;
        }

        .sub-title {
            color: c.$text-light-color;
            font-size: 14px;
        }

        .inner-container {
            padding: 24px;
            display: flex;
            flex-direction: column;
        }



        .support-times {
            margin-top: 24px;

            p {
                margin-bottom: 4px;
            }

            .open-hours {
                color: c.$text-light-color;
                font-size: 14px;
                line-height: 14px;

                &:before {
                    content: "";
                    width: 14px;
                    min-width: 14px;
                    height: 14px;
                    margin-right: 4px;
                    display: inline-block;
                    background: url(../../resources/time.svg) center center no-repeat transparent;
                }
            }
        }

        .main-contact-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                margin-bottom: 6px;
            }

        }

        .form-row-container {
            margin: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 32px;
        }

        .form-row {
            border: 1px solid #ecf3f9;
            border-radius: 19px;
            color: c.$text-light-color;
            height: 38px;
            display: flex;
            padding: 0px 12px;
            align-items: center;
            text-decoration: none;
            margin: 0px 12px 12px 12px;
            cursor: pointer;
            font-size: 14px;

            i {
                margin: auto 0;
                font-size: 22px;
                margin-right: 12px;

                &.fa-phone-alt {
                    color: #3c4146;
                }

                &.fab {
                    &.fa-viber {
                        color: #59267c;
                    }

                    &.fa-skype {
                        color: #00aff0;
                    }

                    &.fa-whatsapp {
                        color: #25D366;
                    }
                }
            }
        }
    }

    .form-tab {

        .main-icon {
            font-size: 24px;
        }

        .title {
            font-size: 20px;
            line-height: 26px;
            font-weight: 500;
        }

        .sub-title {
            color: c.$text-light-color;
            font-size: 14px;
        }

        .send-btn {
            position: relative;
            height: 42px;
            display: flex;
            align-items: center;
            padding: 0 23px;
            background-color: #27304E;
            color: #fff;
            border: none;
            font-weight: 500;
            border-radius: 48px;
            cursor: pointer;
            margin-left: auto;
            font-size: 16px;
            margin: 0 auto;
            margin-top: 16px;
            width: 88px;
            text-align: center;
        }

        .inner-container {
            padding: 24px;
        }

        .form-row {
            .input-title {
                display: inline-block;
                position: relative;
                bottom: -8px;
                left: 10px;
                background-color: #fff;
                color: c.$text-light-color;
                font-size: 12px;
                line-height: 18px;
                padding: 0 5px;
                z-index: 1;
            }

            input,
            textarea {
                width: 100%;
                min-width: 280px;
                height: 32px;
                display: flex;
                box-sizing: border-box;
                border: 0.5px solid #DAE1EF;
                border-radius: 4px;
                outline: none;
                padding: 8px 30px 8px 12px;
                font-size: 14px;
                color: var(--main-theme-text-dark);
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

            }

            textarea {
                height: auto;
            }
        }
    }

    .faq-tab {

        .main-icon {
            font-size: 24px;
        }

        .title {
            font-size: 20px;
            line-height: 26px;
            font-weight: 500;
        }

        .sub-title {
            color: c.$text-light-color;
            font-size: 14px;
        }

        .form-row-container {
            overflow: auto;
            height: 365px;
            padding: 0px 14px 42px 0px;
        }

        .send-btn {
            position: relative;
            height: 42px;
            display: flex;
            align-items: center;
            padding: 0 23px;
            background-color: #27304E;
            color: #fff;
            border: none;
            font-weight: 500;
            border-radius: 48px;
            cursor: pointer;
            margin-left: auto;
            font-size: 16px;
            margin: 0 auto;
            margin-top: 16px;
            width: 88px;
            text-align: center;
        }

        .inner-container {
            padding: 24px;
        }

        .Collapsible {
            margin-bottom: 8px;

            .collapse-body-wrap {
                padding: 14px;
                font-size: 14px;
            }

            .side-indicator {
                margin: auto;
                margin-right: 0;
                display: flex;
                border-radius: 50%;
                border: 1px solid;
                width: 18px;
                height: 18px;
                position: relative;
                flex-shrink: 0;

                &:before {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    font-size: 18px;
                    line-height: 16px;
                    content: "+";
                    text-align: center;
                }
            }


            .is-open {
                .side-indicator {
                    &:before {
                        content: "-";
                    }
                }
            }

            .question-trigger {
                user-select: none;
                cursor: pointer;
                background: #F8F9FC;
                padding: 14px;
                display: flex;
                border-radius: 4px;
                width: 100%;
            }
        }
    }


    .tab-body-icon-wrap {
        padding: 4px;
        border-radius: 50%;
        position: relative;
        width: 38px;
        height: 38px;
        margin-bottom: 32px;
        color: #fff;
        background: transparent;
        border: 3px solid;
        display: flex;
        margin: 6px;

        i {
            color: #fff;
            width: 24px;
            line-height: 27px;
            font-size: 16px;
            text-align: center;
            margin: auto;
            z-index: 1;
        }

        &:before {
            position: absolute;
            content: '';
            bottom: 0;
            top: -3px;
            left: -3px;
            right: 0;
            opacity: 0.75;
            border-radius: 50%;
            border: 19px solid;
            border-color: inherit;
        }

        &:after {
            position: absolute;
            content: '';
            bottom: -10px;
            top: -10px;
            left: -10px;
            right: -10px;
            opacity: 0.25;
            border-radius: 50%;
            border: 7px solid;
            border-color: inherit;
            border-top: 7px solid transparent;
            transform: rotate(135deg);
        }
    }
}