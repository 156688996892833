@use '../../../variables'as c;

.settings-nav {
    border-right: 6px solid #f0f1f4;
    width: 240px;
    background: #F5F6F8;
    overflow: hidden;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    overflow: auto;

    padding: 12px 16px;
    font-size: 14px;

    .separator-desc {
        color: c.$text-light-color;
        font-size: 12px;
    }


    h2 {
        padding: 0;
        margin: 12px 0px 0px 0px;
    }

    .option-wrap {
        margin-bottom: 6px;
        padding: 3px 9px;
        border-radius: 4px;
        user-select: none;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        color: c.$text-light-color;

        .group-sub-title {
            font-weight: 600;
        }

        .group-desc {
            font-size: 12px;
        }

        &:hover {
            background-color: adjust-color(c.$text-light-color, $alpha: -0.90);
        }

        &.active {
            background-color: #9686f220;

            .group-sub-title {
                color: #9686f2;
            }
        }

    }



}