@use '../../variables'as c;

$dark-text: #0e1111;
$inactive-text: #999;

$main-color2: #402f5d;
$main-color:#9686f2;
$error-text: #E64747;
$primary-soft: #EDE3FF;
$base-color: #3a3837;

$google-color: #de5246;
$faceboook-color: #4267B2;

$dark-text: #0e1111;
$inactive-text: #999;
$error-text: #E64747;

.auth-wrap {
    display: flex;
    width: 100vw;
    min-height: 100vh;
}

.auth-view {
    .form-group {
        margin-bottom: 0;
    }

    .auth-form {
        text-align: center;

        .login-title {
            font-style: normal;
            font-weight: bold;
            font-size: 40px;
            letter-spacing: 0.005em;
            color: #1B2537;
        }

        .login-details {
            font-weight: bold;
            font-size: 24px;
            line-height: 25px;
            text-align: center;
            color: #302a4a;
        }

    }

    .df-btn {
        font-size: 16px;
        text-decoration: none;
        padding: 6px 16px;
        background-color: $main-color;
        border: 1px solid $main-color;
        border-radius: 4px;
        color: #fff;
        outline: none;
        cursor: pointer;

        -webkit-transition: all 100ms cubic-bezier(0.7, 0.01, 1, 1);
        -moz-transition: all 100ms cubic-bezier(0.7, 0.01, 1, 1);
        -o-transition: all 100ms cubic-bezier(0.7, 0.01, 1, 1);
        transition: all 100ms cubic-bezier(0.7, 0.01, 1, 1);
    }

    .df-btn:hover {
        background-color: darken($main-color, 5%);
        border: 1px solid darken($main-color, 5%);
    }

    .df-btn:focus {
        box-shadow: 0 0 0 3px rgba($main-color, 0.5);
    }

    .df-btn:disabled,
    .df-btn[disabled] {
        background-color: #bab6bf;
        border: 1px solid #bab6bf;
    }

    .df-btn-inverse {
        background: transparent;
        border: 1px solid #3a3837;
    }

    .df-btn-inverse:hover {
        color: #fff;
    }

    .auth-switch {
        background-color: #ebe5f1;
        border-color: #ebe5f1;
        color: $main-color;
    }

    .auth-switch:hover {
        background-color: #d7c7e8;
        border-color: #d7c7e8;
        color: $main-color;
    }

    .auth-switch:focus {
        box-shadow: 0 0 0 3px #5f2db985;
    }

    .forgot-password {
        color: #646A73;
        text-decoration: none;
        outline: none;
        font-size: 14px;

        &:hover {
            text-decoration: underline;
        }
    }

    .forgot-password {
        color: #646A73;
        text-decoration: none;
        outline: none;
        font-size: 14px;
    }

    .password-requirements {
        font-size: 12px;
        color: $inactive-text;

        .error {
            color: $error-text;
        }
    }

    .tos-agree-text {
        margin-top: 18px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: .3px;
        padding: 10px 30px;
        text-align: left;
        color: $inactive-text;
    }

    .sidelines {
        text-align: center;
        border-bottom: 1px solid #cacaca;
        height: 0.5em;
        line-height: 1;
        font-size: 14px;
        color: #cacaca;
        margin-top: 22px;
    }

    .sidelines span {
        display: inline-block;
        background: #fff;
        padding: 0 0.25em;
    }

    .social-grid {
        margin-top: 22px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 14px;
        row-gap: 8px;


        a {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .login-title {
        margin-bottom: 0;
        margin-top: 60px;
    }

    .google {
        background-color: #de5246;
        border: 1px solid #de5246;
        color: #fff;
        font-weight: 500;

        &.df-btn:hover {
            background-color: #d14a3d;
            border: 1px solid #d14a3d;
        }

        &.df-btn:focus {
            box-shadow: 0 0 0 3px #d14a3d85;
        }
    }

    .facebook {
        background-color: #1877F2;
        border: 1px solid #1877F2;
        color: #fff;
        font-weight: 500;

        &.df-btn:hover {
            background-color: #086aeb;
            border: 1px solid #086aeb;
        }

        &.df-btn:focus {
            box-shadow: 0 0 0 3px #086aeb85
        }
    }

    .apple {
        background-color: #000;
        border: 1px solid #000;
        color: #fff;
        font-weight: 500;

        &.df-btn:hover {
            background-color: #000;
            border: 1px solid #000;
        }

        &.two-colums {
            grid-column: 1/3;
            justify-self: center;
        }

        &.df-btn:focus {
            box-shadow: 0 0 0 3px #00000085
        }
    }

    .linkedin {
        background-color: #0e76a8;
        border: 1px solid #0e76a8;
        color: #fff;
    }

    .microsoft {
        background-color: #00A4EF;
        border: 1px solid #00A4EF;
        color: #fff;
    }

    .general-error {
        height: 18px;
        font-size: 12px;
        color: $error-text;
    }

    &.mob {
        background: rgb(245, 246, 248);
        padding: 38px;
        font-size: 16px;

        .login-title {
            text-align: center;
            font-style: normal;
            font-size: 32px;
            font-weight: 400;
            margin-top: 24px;
        }

        .login-details {
            text-align: center;
            color: c.$text-light-color;
            font-size: 16px;
        }

        .sidelines span {
            background: rgb(245, 246, 248);
        }

        .social-grid {
            grid-template-columns: 1fr;
            text-align: center;
        }

        .tos-agree-text {
            font-size: 14px;
            padding: 10px 0px;
            color: c.$text-light-color;
        }
    }

    .left-content {
        color: #fff;
        display: flex;
        flex-direction: column;
        position: relative;
        flex-grow: 1;

        margin: 22px 32px;

        .top-logo {
            margin: 0;

            img {
                max-width: 120px;
            }
        }

        .illustration {
            margin: auto;
            height: 80%;
            width: 55%;
            object-fit: contain;
        }

        .left-body {
            flex-grow: 1;
            display: flex;
            padding-top: 55px;

            .left-bg {
                width: 530px;
                position: absolute;
                opacity: 0.03;
                bottom: -135px;
                right: -200px;
            }

            .text-wrap {
                z-index: 2;
                display: flex;
                flex-direction: column;
            }

            img {
                z-index: 0;
            }
        }

        .left-txt-main {
            font-size: 38px;
            line-height: 1.2;
            font-weight: 800;
            color: #302a4a;
        }

        .left-illustration {
            width: 320px;
        }
        
        .left-txt-sub {
            font-size: 18px;
            line-height: 1.1;
            font-weight: 500;
            color: #302a4a;
            margin-bottom: 18px;

            .demo-link {
                color: inherit;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}

.top-logo {
    margin: 32px 48px;

    img {
        height: 48px;
        max-width: 120px;
    }
}

.side-logo {
    width: 100%;
    height: calc(100vh - 96px);
    display: flex;

    img {
        height: 100%;
        max-width: 95%;
        margin: auto;
    }
}

.form-wrap {
    width: 100%;
    display: flex;
}