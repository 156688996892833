@use '../../../variables'as c;

.tw-tag {
    background: #fff;
    color: #03a9f4;
    border: 1px solid #03a9f4;
    display: inline-flex;
    padding: 3px 9px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 12px;
    margin: 2px 3px;
    outline: none;
    max-width: #{"min(180px, calc(100% - 4px))"};
    user-select: none;

    &.hasX {
        position: relative;
        padding-right: 22px;

        i.remove {
            position: absolute;
            padding: 2px;
            top: 2px;
            right: 9px;
        }
    }

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.add-tw-tag {
    background: none;
    font-size: 14px;
    width: 100%;
    position: relative;

    input {
        border: none;
        outline: none;
        width: 100%;
        padding: 6px 12px 6px 32px;
        line-height: 16px;
        border-radius: 6px;
        border: 1px solid #ECF3F9;
    }

    &::before {
        position: absolute;
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        content: "\f02b";
        color: #b7a4e6;
        left: 12px;
        top: 6px;
    }
}

.add-tw-tag-wrap {
    .dropdown-menu {
        width: 100%;
    }

    .dropdown-main-menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        max-height: 250px;
        overflow: auto;
    }
}