.ellipsis-loading {
    &.chat-list-loader {
        text-align: center;
        font-size: 26px;
        line-height: 18px;
        color: #bdc8e0;
        margin-bottom: 16px;
    }
    
    span {
        opacity: 0;
        -webkit-animation: dot1 1.8s infinite;
    }

    span+span {
        opacity: 0;
        -webkit-animation: dot2 1.8s infinite;
    }

    span+span+span {
        opacity: 0;
        -webkit-animation: dot3 1.8s infinite;
    }

    @keyframes dot1 {
        0% {
            opacity: 0;
        }

        25% {
            opacity: 1;
        }

        90% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes dot2 {
        0% {
            opacity: 0;
        }

        25% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        90% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes dot3 {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 0;
        }

        75% {
            opacity: 1;
        }

        90% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
}