@use '../../variables'as c;

.settings-page {
    color: c.$text-primary-color;

    .tab-error,
    .tab-loader {
        font-size: 18px;
        color: #899abc;
        text-align: center;
        margin-top: 46px;
    }

    .settings-container {
        padding: 18px 32px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    h2 {
        font-size: 24px;
        line-height: 38px;
        font-weight: 500;
        margin: 0 -10px;
        z-index: 4;
        padding: 15px 20px;
    }

    h3 {
        font-size: 16px;
        color: c.$text-primary-color;
        font-weight: 500;
        margin-bottom: 16px;
    }

    h5 {
        font-size: 16px;
        color: #899abc;
        font-weight: 500;
        margin-bottom: 8px;
    }

    .info-description {
        font-size: 14px;
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
        color: #646a73;
    }

    .plan-period-choice-wrap {
        color: #646a73;
        display: flex;
        justify-content: center;
        font-size: 13px;
 
        .plan-period-choice {
            cursor: pointer;
            //width: 140px;
            user-select: none;

            background: #f5f6f8;
            padding: 2px 12px;
            border: 1px solid #302b4a;
            &:first-child {
                border-radius: 6px 0px 0px 6px;
                border-right: none;
            }

            &:last-child {
                border-radius: 0px 6px 6px 0px;
                border-left: none;
            }

            &.selected {
                text-decoration: underline;
                background: #302b4a;
                color: #fff;
            }

            &:last-child {
                text-align: left;
            }

            &:first-child {
                text-align: right;
            }
        }
    }

    .package-wrap {
        .price-wrap {
            font-size: 18px;
            margin-bottom: 6px;

            .package-type {
                font-size: 14px;
            }


            &.inactive {
                color: #bdc8e4;
                font-size: 14px;
                display: none;

                .package-type {
                    font-size: 12px;
                }

                .price {
                    color: #bdc8e4;
                }
            }
        }

        .vat-warning {
            font-size: 12px;
            margin-bottom: 4px;
        }
    }


    .form-control {
        border-color: #f5f6f8;

        &:disabled {
            background-color: #f5f6f8;
        }
    }

    .dropdown-label {
        line-height: 31px;
        color: c.$text-light-color;
        margin: 0;
    }

    .custom-switch {
        padding-left: 54px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;

        .custom-control-input {
            width: 50px;
            height: 32px;
        }

        .custom-control-label::before {
            background-color: #F5F6F8;
            border: #F5F6F8 solid 1px;
            left: -50px;
            width: 42px;
            height: 24px;
            border-radius: 12px;
        }

        .custom-control-label::after {
            top: 7px;
            left: -47px;
            width: 18px;
            height: 18px;
            border-radius: 9px;
            background-color: c.$text-light-color;
        }

        .custom-control-input:checked~.custom-control-label::after {
            transform: translateX(18px);
        }

        label {
            color: c.$text-light-color;
            user-select: none;
            cursor: pointer;
        }
    }

    .accordion.chat-feature {
        .card {
            border: 1px solid #EBF1FE;
            overflow: visible;

            .card-header {
                font-size: 18px;
                color: c.$text-light-color;
                margin: -1px;
                border-radius: 6px;
                border: 1px solid #EBF1FE;
                background: none;
                display: flex;

                &.selected {
                    border-color: #9686f2;
                    color: #9686f2;
                    box-shadow: 0px 4px 14px rgba(136, 151, 183, 0.15);
                }

            }
        }
    }

    .time-input {
        input {
            color: #495057;
            border: none;
            outline: none;
            min-width: 20px;
            flex: 1;
        }
    }

    .remove-schedule {
        color: #9686f2;
        cursor: pointer;
        user-select: none;
        font-size: 14px;
    }

    .add-schedule {
        color: #9686f2;
        cursor: pointer;
        user-select: none;
        font-size: 16px;
        display: flex;
        margin-top: 6px;

        i {
            font-size: 1.3em;
            margin-top: auto;
            margin-bottom: auto;
        }

        span {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .settings-box {
        flex: 1;
        overflow: auto;
        padding: 0px 8px;
        animation: fadeIn 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);


        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        form {
            max-width: 820px;
        }

        .member {
            background: #ffffff;
            box-shadow: 0px 8px 24px rgba(27, 37, 55, 0.04);
            border-radius: 10px;
            width: 100%;
            padding: 18px 24px;
            border: solid 1px transparent;
        }

        .member:hover {
            border: 1px solid #ced8e0;
        }

        .member.active {
            border: 1px solid #9686f2;
        }

        .author .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: solid #ffffff 2px;
            background-color: #f2f4f7;
            color: #7D55C8;
            font-size: 14px;
            text-transform: uppercase;
            margin-right: 18px;
        }

        .author .avatar img {
            height: 100%;
        }

        .author .author-name {
            font-size: 14px;
            color: c.$text-primary-color;
            margin-bottom: 4px;
            line-height: 22px;

            &.pending {
                color: #646a73;
            }
        }

        .author .email {
            font-size: 13px;
            color: #646a73;
            margin-bottom: 0;
            line-height: 18px;
        }


        .add-new-team-member span {
            line-height: 38px;
        }

        .add-new-team-member .dropdown-toggle {
            background: #ffffff;
            border: 1px solid #9ca5af;
            box-sizing: border-box;
            border-radius: 8px;
            height: 40px;
            padding: 0 8px 0 8px;
            color: #1b2537;
            display: inline-flex;
            align-items: center;
        }

        .add-new-team-member .dropdown-toggle .add-new {
            display: flex;
            align-items: center;
        }

        .add-new-team-member .dropdown-toggle:hover {
            text-decoration: none;
        }

        .add-new-team-member .dropdown-toggle img {
            margin-left: 8px;
        }

        .add-new-team-member .dropdown-toggle::after {
            display: none;
        }

        .add-new-team-member .dropdown-toggle i {
            color: #646a73;
        }

        .add-new-team-member.show .dropdown-toggle {
            border: 1px solid #7D55C8;
        }

        .add-new-team-member.dropdown {
            position: relative;
        }

        .add-new-team-member.dropdown .dropdown-menu {
            border: none;
            min-width: 200px;
            box-shadow: 0px 8px 24px rgba(27, 37, 55, 0.08);
            padding: 0;
        }

        .add-new-team-member.dropdown .dropdown-menu .dropdown-item {
            height: 50px;
            box-sizing: border-box;
            background-color: #fcfdff;
            border-bottom: solid 1px #f2f4f7;
            display: flex;
            align-items: center;
            padding: 0 20px;

            &:hover {
                text-decoration: none;
                background-color: #e5ddf4;
            }
        }

        .add-new-team-member.dropdown .dropdown-menu .dropdown-item:last-child {
            border-bottom: none;
        }

        .add-new-team-member.dropdown .dropdown-menu .dropdown-item img {
            margin-right: 20px;
        }

        .facebook {
            background-color: #1877F2;
            border: 1px solid #1877F2;
            color: #fff;
            font-weight: 500;

            &:hover {
                background-color: #086aeb;
                border: 1px solid #086aeb;
            }

            &:active,
            &:focus {
                box-shadow: 0 0 0 3px #086aeb85 !important;
                background-color: #086aeb !important;
                border: 1px solid #086aeb !important;
            }
        }
    }

    .settings-types {
        border-bottom: 2px solid #EBF1FE;
        display: flex;
        font-size: 18px;
        margin-bottom: 32px;

        a {
            color: c.$text-light-color;
            padding: 12px 18px;
            text-decoration: none;
            border-bottom: solid 2px #EBF1FE;
            margin-bottom: -2px;

            &.active {
                color: #9686f2;
                border-bottom: solid 2px #9686f2;

            }
        }
    }

    .chat-settings-wrap {
        display: flex;

        form {
            flex: 1
        }

        .preview-wrap {
            flex: 1;
        }

        .patterns-wrap {


            .pattern-select-wrap {
                width: 124px;
                height: 62px;
                border: 1px solid #eaf1ff;
                border-radius: 4px;
                margin-bottom: 6px;
                margin-right: 12px;
                display: flex;
                color: #646a73;
                cursor: pointer;

                position: relative;

                .text-overlay {
                    display: flex;
                    position: absolute;
                    inset: 0;
                }

                .pattern-preview {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            input:checked+.pattern-select-wrap {
                border-color: #9686f2;
            }
        }



        .form-group.bottom-separator {
            margin-bottom: 1rem;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid #eaf1ff;
        }

        .logo-preview-wrap {
            position: relative;
            display: inline-flex;

            .remove-image {
                position: absolute;
                width: 24px;
                height: 25px;
                background: #fff;
                display: flex;
                border-radius: 14px;
                font-size: 12px;
                color: #646a73;
                cursor: pointer;
                right: -6px;
                top: -6px;
                border: 1px solid rgba(0, 0, 0, 0.1);
            }

            .logo-preview {
                cursor: pointer;
                width: 62px;
                height: 62px;
                padding: 4px;
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.1);

                object-fit: cover;
            }
        }


        .swatch {
            display: inline-block;
            cursor: pointer;
        }

        .swatch-color {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, .1)
        }

        .color-wrap {
            position: relative;
        }

        .color-popover {
            position: absolute;
            z-index: 2;

            .popover-cover {
                position: fixed;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
            }
        }

        .form-email-input {
            position: relative;
            padding-right: 32px;

            .remove-email {
                position: absolute;
                width: 24px;
                height: 25px;
                background: #fff;
                display: flex;
                border-radius: 14px;
                font-size: 12px;
                color: #646a73;
                cursor: pointer;
                right: -6px;
                bottom: 8px;
            }
        }
    }

    .faq-lang-select-lable {
        margin-right: 12px;
        color: c.$text-light-color;
        margin-top: auto;
        margin-bottom: auto;
    }

    .faq-selectable-lang {
        padding: 2px 6px;
        border-radius: 4px;
        font-size: 12px;
        margin: 2px 4px;
        color: c.$text-light-color;
        cursor: pointer;
        background: #fbfdff;

        &:hover {
            background: #fff;
        }

        &.active {
            color: #fff;
            background: #9686f2;
        }
    }


    .callus-card {
        .fa-phone-alt {
            color: #3c4146;
            font-size: 16px;
        }

        .fab {
            &.fa-viber {
                color: #59267c;
                font-size: 16px;
            }

            &.fa-skype {
                color: #00aff0;
                font-size: 16px;
            }

            &.fa-whatsapp {
                color: #25D366;
                font-size: 16px;
            }
        }
    }
}

.device-details {
    margin-bottom: 12px;

    i {
        color: #788790;
    }

    .device-name {
        font-weight: bold;
    }

    .device-info {
        font-size: 12px;
        color: c.$text-light-color;
    }
}

.select-container {
    position: relative;
    margin-bottom: 12px;
    color: c.$text-light-color;
    text-decoration: none;
    display: block;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 6px;

    &.sm-select {
        margin-bottom: 0;
        height: 26px;
        line-height: 24px;
        padding: 0px 12px;
    }

    select {
        display: flex;
        opacity: 0;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        cursor: pointer;
    }
}


.plain-button {
    background: none;
    border: none;
    outline: none;
    color: c.$text-light-color;
    font-size: 14px;
    cursor: pointer;
    line-height: 24px;

    &:hover {
        color: #9686f2;
    }
}


.subsciption-tab {
    color: #646a73;
    text-align: center;

    .text-secondary {
        height: 24px;
        margin-bottom: 6px;
    }

    .main-text-title {
        color: rgb(37, 48, 80);
        font-weight: 700;
        margin-top: 32px;
    }

    .main-text {
        font-size: 18px;
        text-align: center;
        margin-bottom: 112px;
    }

    .price {
        color: #9686f2;
        font-weight: 600;
    }

    .truster-brand-logo {
        width: 50%;
        max-height: 84px;
        padding: 8px 12px;
    }

    .brands-wrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .brand-img {
            width: 136px;
            height: 76px;
            margin: 0px 4px;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }

    .subscriptions {
        justify-content: center;

        .limited-offer {
            position: absolute;
            top: -17px;
            left: calc(50% - 80px);

            .offer-percentage {
                background-color: #fff;
                color: #9686f2;
                border: 1px solid #9686f2;
                height: 34px;
                line-height: 34px;
                font-size: 16px;
                font-weight: 500;
                width: 160px;
                border-radius: 34px;
            }

            span {
                color: #9686f2;
                font-size: 12px;
                font-weight: 600;
            }
        }

        .col {
            padding: 34px 24px 12px 24px;
            background-color: #ffffff;
            box-shadow: 0px 4px 14px rgba(136, 151, 183, 0.15);
            //box-shadow: 0px 4px 14px rgba(136, 151, 183, 0.15);
            border-radius: 20px;
            max-width: 350px;

            &.no-shadow {
                box-shadow: none;
            }

            &.active {
                border: 1px solid #9686f2;
                box-shadow: 0px 4px 14px rgba(84, 56, 168, 0.24);
                //box-shadow: 0px 4px 14px rgba(136, 151, 183, 0.15);
            }

            .mng-btn {
                padding: 2px 16px;
                background-color: #69717a;
                color: #fff;
                border-radius: 20px;
                border-top-left-radius: 4px;
            }

            a.btn,
            button {
                white-space: nowrap;
                border: 0;
                outline: 0;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                padding: 0 14px;
                color: #fff;
                border-radius: 20px;
                border-top-left-radius: 4px;
                font-size: 15px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.025em;
                background-color: #00d59c;
                text-decoration: none;
                -webkit-transition: all 150ms ease;
                transition: all 150ms ease;
                min-width: 210px;

                &:focus {
                    outline: none;
                }

                .loader {
                    display: none;
                }
            }

            hr {
                margin: 22px -24px;
            }

            .sub-info {
                font-size: 14px;
            }

            ul {
                list-style: none;
                padding: 0px;
                text-align: left;

                li {
                    margin-left: 6px;

                    &:before {
                        content: '✓';
                        color: #9686f2;
                        margin-right: 8px;
                    }

                    &.negative {
                        &:before {
                            content: '-';
                        }
                    }
                }
            }
        }


        .col:nth-child(2) {
            z-index: 1;
            margin: -18px 0px;
            box-shadow: 0px 4px 14px rgba(84, 56, 168, 0.24);
            border: 1px solid #9686f282;

            .offer-percentage {
                background-color: #9686f2;
                color: #fff;
                height: 34px;
                line-height: 34px;
                font-size: 16px;
                font-weight: 500;
                width: 160px;
                border-radius: 34px;
            }

        }


        h3 {
            color: #131a26;
            font-weight: 700;
        }
    }
}