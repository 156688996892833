@use '../../../variables'as c;

.cmn-thin-btn-wrap {
    display: flex;
    align-items: center;

    .cmn-thin-btn {
        cursor: pointer;
        border-radius: 6px;
        padding: 3px 8px;
        background-color: #F5F6F8;
        color: c.$text-light-color;
        user-select: none;
        font-size: 14px;

        &.txt-small {
            font-size: 12px;
        }
        
        &.primary {
            background-color: #9686f2;
            color: #fff;

            &:hover {
                background-color: #7863ee;
            }
        }

        &.dark {
            background-color: #42526e;
            color: #fff;

            &:hover {
                background-color: #394861;
            }
        }

        &:hover {
            background-color: #e5e6e8;
        }


    }

    &.bg-none {
        .cmn-thin-btn {
            background-color: transparent;
        }
    }

    &.thin-primary {
        .cmn-thin-btn{
            padding: 0 8px;
            background: #9686f2;
            color: #fff;

            &:hover {
                background-color: #7863ee;
            }
        }
    }
}

.cmn-vert-separator {
    display: flex;
    flex-direction: row;
    user-select: none;
    margin: 0px 6px;
    border-left: 1px solid #DAE1EF;
    height: 100%;
}

.cmn-separator {
    display: flex;
    flex-direction: row;
    user-select: none;

    margin: 12px 0px;
    color: c.$text-extra-light-color;
    font-size: 12px;
    text-align: center;

    &:before,
    &:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid #DAE1EF;
        margin: auto 8px;
    }

    &.full-width {
        &:before {
            margin: auto 8px auto 0px;
        }

        &:after {
            margin: auto 0px auto 8px;
        }
    }

}


.cmn-separator-dots {
    display: flex;
    flex-direction: row;
    user-select: none;

    margin: 12px 0px;
    color: c.$text-light-color;
    font-size: 12px;
    text-align: center;

    &:before,
    &:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid #DAE1EF;
    }

    &:before {
        margin: auto 8px auto 0px;
    }

    &:after {
        margin: auto 8px auto 8px;
    }

}