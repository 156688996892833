@use '../../variables'as c;

.mb-default-pg {
    min-width: 100%;
    background: rgb(245, 246, 248);
    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;

    .mb-topbar {
        display: flex;
        flex-direction: row;
        background-color: #fff;
        box-shadow: 0px 4px 24px rgba(136, 151, 183, 0.2);
        height: 62px;
        width: 100%;
        padding: 16px;
        z-index: 1;

        .user-avatar {
            width: 28px;
            height: 28px;
            background: linear-gradient(0deg, #DAE1EF, #DAE1EF), #C4C4C4;
            border-radius: 14px;
            color: c.$text-light-color;
            text-align: center;
            line-height: 28px;
            font-size: 12px;
            font-weight: 600;
        }

        .dr-toggle {
            text-decoration: none;
            color: c.$text-primary-color;
            cursor: pointer;
        }

        .sites-switch {
            text-decoration: none;
            color: c.$text-primary-color;
            margin-right: 12px;
        }

        .header-icon {
            text-decoration: none;
            color: #9686f2;
        }

        .dropdown-menu {
            box-shadow: 2px 2px 5px 3px rgba(218, 225, 239, 0.8);
            border-radius: 6px;
            border: none;
        }

        .dropdown-item.active,
        .dropdown-item:active {
            color: #fff;
            background-color: #9686f271;
        }

        .site-select {
            padding: 6px 16px;
            white-space: nowrap;
            display: flex;

            span {
                flex: 1;
                line-height: 30px;
            }
        }
    }

    .mb-bottombar {
        display: flex;
        flex-direction: row;
        background-color: #fff;
        box-shadow: 0px 4px 24px rgba(136, 151, 183, 0.2);
        height: 62px;
        width: 100%;
        padding: 16px;
        z-index: 1;
        bottom: 0;
        left: 0;
        position: fixed;
    }

    .mb-main-area {}
}

.default-pg {
    width: 100vw;
    height: 100vh;
    background: #f0f1f4;
    display: flex;
    flex-direction: column;

    .pg-main-area {
        overflow: auto;
        display: flex;
        flex: 1;

        .pg-content-area {
            padding: 24px 24px 24px 12px;
            overflow: auto;
            flex: 1;
            display: flex;

            &.no-sidebar {
                padding: 24px;
            }

            &.no-pad {
                padding: 0px;
            }
        }
    }

    .content-card {
        display: flex;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0px 4px 14px rgba(136, 151, 183, 0.15);

        &.main-card {
            height: 100%;
            width: 100%;
        }

        &.dynamic-main-card {
            max-height: 100%;
        }
    }
}

.notifcations-request {
    background-color: #9686f2;
    color: #fff;
    text-align: center;
    font-size: 14px;
    padding: 6px 0px;

    &.error-notification {
        background-color: c.$error-color;
    }

    a,
    span {
        color: #fff;
        padding: 0px 6px;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: #fff;
        }
    }
}


.loading-ellipsis:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 120ms infinite;
    animation: ellipsis steps(4, end) 1200ms infinite;
    content: "\2026";
    /* ascii code for the ellipsis character */
    width: 0px;


    @keyframes ellipsis {
        to {
            width: 16px;
        }
    }

    @-webkit-keyframes ellipsis {
        to {
            width: 16px;
        }
    }
}