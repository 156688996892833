@use '../../../variables'as c;

.settings-container {
    .title-row {
        display: flex;
        padding: 15px 20px 0px 0px;
        margin-bottom: 12px;
        flex-direction: column;
        border-bottom: 2px solid #eaf1ff;


        .title {
            font-size: 24px;
            line-height: 38px;
            font-weight: 500;
            margin-left: 15px;
            z-index: 4;
        }

        .title-actions {
            display: flex;
            margin-left: auto;
        }
    }

    .sub-option-wrap {
        min-height: 15px;

        .sub-option {
            border-bottom: 2px solid transparent;
            margin-top: 6px;
            margin-bottom: -2px;
            padding: 4px 16px;
            font-size: 14px;
            cursor: pointer;
            color: c.$text-light-color;
            user-select: none;

            &:hover {
                border-bottom: 2px solid #9686f2;
            }

            &.active {
                border-bottom: 2px solid #9686f2;
                background: #9686f220;
                color: c.$text-primary-color;
                border-radius: 6px 6px 0px 0px;

                i {
                    color: #9686f2;
                }
            }

            &.errors {
                color: #E64747;

                i {
                    color: #E64747;
                }
            }
        }
    }

    .domain-wrap {
            background: #ffffff;
            box-shadow: 0px 8px 14px rgba(27, 37, 55, 0.04);
            border: 1px solid #ced8e0;

            border-radius: 10px;
            width: 100%;
            padding: 18px 24px;


        
        &:hover {
            border-color: #45c4a0;
        }
        
        .warning-msg {
            background: #f8f9fa;
            border: 1px solid #f1f3f6;
            border-radius: 6px;
            padding: 2px 6px;
            font-size: 12px;
            font-weight: 400;
            color: #E64747;
        }

        .domain-name {
            font-size: 22px;
            color: #44546f;
            margin-bottom: 4px;
            line-height: 22px;
            font-weight: 600;
        }

        .domain-ver-table {
            font-size: 14px;
            //box-shadow: inset 0px 0px 2px 1px rgb(0 0 0 / 11%);
        
            thead {
                tr {
                    background: rgb(245, 246, 248);
                    color: rgb(66, 82, 110);
                }
            }
    
            tr{
                border-bottom: 1px solid rgb(228, 233, 242);
                td,th {
                    padding: 4px 6px;

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    
    }
    .remove-qna {
        padding: 6px 12px;
        color: #44546f;

        &:hover {
            color: #E64747;
        }
    }
}

.stepper-wrapper {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    color: #bdc8e0;

    width: 100%;
    max-width: 375px;
    align-self: center;
  }

  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  
  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #bdc8e0;
    width: 100%;
    top: 12px;
    left: -50%;
    z-index: 2;
  }
  
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #bdc8e0;
    width: 100%;
    top: 12px;
    left: 50%;
    z-index: 2;
  }
  
  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #bdc8e0;
    color: #fff;
    margin-bottom: 6px;
  }
  
  .stepper-item.active {
    color: c.$success-color;

    .step-counter {
        border: 1px solid c.$success-color;
        background-color: #fff;
        color: c.$success-color;
    }
    
  }
  
  .stepper-item.completed {
    color: #bdc8e0; 
    
    .step-counter {
        background-color: c.$success-color;
        color: #fff;
    }
  }  

  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid c.$success-color;
    width: 100%;
    top: 12px;
    left: 50%;
    z-index: 3;
  }
  
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }

  .assistant-form-wrap {
    label{
        .general-error, .general-success {
            font-size: 11px;
        }
    }
  }