@use '../../variables'as c;

.guide-modal {

    h2 {
        font-size: 28px;
        line-height: 38px;
        font-weight: 500;
    }

    p {
        margin-bottom: 6px;
    }

    .guide-logo {
        max-width: 120px;
        margin-bottom: 24px;
    }

    .guide-step-img {
        max-width: 100%;
        margin-top: 16px;
    }

    code {
        color: rgb(75, 75, 75);
        background-color: rgb(220, 220, 220);
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 4px;
        margin: 3px;
    }

    figcaption {
        font-size: 12px;
        color: #a9acaf;
        margin-left: 6px;
    }

    textarea {
        background-color: #f5f6f8;
        border: none;
        resize: none;
    }

    .close-modal {
        position: absolute;
        top: 14px;
        right: 22px;
        cursor: pointer;
        padding: 0px 6px;
        font-size: 22px;
    }
}