@use '../../../variables'as c;


.btn.integration-btn {

    //    color: #5a33ae;
    //    background-color: #f5f6f8;
    //   border-color: #f5f6f8;
    margin: 3px 6px;

    &:hover,
    &:active {
        background-color: #ebe6f6;
    }

    img {
        max-width: 120px;
        max-height: 26px;
        height: 26px;
    }
}