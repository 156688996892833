@use '../../variables'as c;

.toasts-center-container {
    top: 40px;
    left: 50%;
    width: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    position: fixed;
    z-index: 1041;
    pointer-events: all;
}

.toasts-container {
    bottom: 25px;
    right: 25px;
    position: fixed;
    z-index: 1041;
    pointer-events: all;
}


.toasts-center-container,
.toasts-container {
    .toasts-list {
        .tw-toast {
            margin: 12px;
            bottom: 25px;
            right: 25px;
            padding: 10px 16px;
            box-shadow: 0px 4px 8px rgba(27, 37, 55, 0.12);
            border-radius: 6px;
            border: none;
            width: 285px;
            color: #1b2537;

            .toast-body {
                padding: 0px;
            }

            .fa-error {
                color: c.$error-color;
                font-size: 2em;
                padding-right: 16px;
            }

            .fa-success {
                color: #4BB543;
                font-size: 2em;
                padding-right: 16px;
            }
        }

        &.dark {
            .tw-toast {
                background: #323232;
                color: #d4d4d4;
                width: auto;
            }
        }
    }
}