@use '../../../variables'as c;

.faq-card {

    .Collapsible {
        margin-bottom: 12px;
        position: relative;

        .swap-wrap {
            position: absolute;
            right: -22px;
            top: 6px;

            .swap {
                cursor: pointer;
                color: #d7e7f3;

                &:hover {
                    color: #8597ba;
                }
            }
        }

        .trigger-row {
            padding: 10px 12px;
            border: 1px solid #d7e7f3;
            border-radius: 6px;
            display: flex;

            .missing-translations {
                i {
                    color: #ffb84a;
                }
            }

            input {
                border: none;
                outline: none;
                flex: 1 1;
                padding: 0;
                color: c.$text-primary-color;
                margin-right: 24px;

                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #8597ba;
                    opacity: 1;
                    /* Firefox */
                }

            }

            button {
                background: none;
                border: none;
                outline: none;
                color: c.$text-light-color;

                &:hover {
                    color: #9686f2;
                }
            }
        }

        &.collapse-open {
            .trigger-row {
                border-radius: 6px 6px 0px 0px;
            }

            .Collapsible__contentOuter {
                border: 1px solid #d7e7f3;
                border-radius: 0px 0px 6px 6px;
                border-top: none;
                visibility: inherit;
            }

            textarea {
                width: 100%;
                resize: none;
                border: none;
                outline: none;
                min-height: 105px;
                padding: 8px 12px;


                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #8597ba;
                    opacity: 1;
                    /* Firefox */
                }
            }
        }

        .Collapsible__contentOuter {
            visibility: hidden;
        }

        .Collapsible__contentOuter {}
    }
}


.translateable-input {
    .char-counter { 
        color: #8597ba;
        font-size: 14px;
        margin-left: auto;
    
        &.text-danger {
            color: #ff4d4f;
        }
    }

    textarea {
        resize: none;
    }
}

