@use '../../../variables'as c;

.cst-dropdown-btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.add-new-item-input {
    padding: 2px 12px;
    width: 100%;
    background: none;
    border: none;
    outline: none;
    color: #6b767f;
}

.form-toggle-with-prefix-qp,
.form-toggle-with-prefix {
    display: flex;
    align-items: center;
    padding: 0px;
    background: #f5f6f8;
    border-radius: 6px;
    font-size: 14px;
    color: c.$text-light-color;
    cursor: pointer;
    height: 27px;

    &:not(.disabled) {
        &:hover {
            background: #e5e6e8;
        }
    }

    .prefix-label {
        padding-left: 8px;
        user-select: none;
        margin-right: 6px;
        white-space: nowrap;
    }

    .form-control {
        padding: 4px 10px;
        border: 1px solid #f5f6f8;
        user-select: none;

        &.disabled {
            color: #b5c2da;
        }
    }


}

.form-toggle-with-prefix-qp {
    .form-control {
        padding: 0px;
        overflow: hidden;

        .fc-sub-btn {
            padding: 4px 10px;
            //color: #b5c2da;

            &:not(:first-child) {
                border-left: 1px solid #f5f6f8;
            }

            &:hover {
                background-color: #e5e6e820;
            }

            &.active {
                color: #9686f2;
                // background-color: #9686f210;
            }
        }
    }
}

.search-dropdown-wrap {
    &.limit-len {
        .form-toggle-with-prefix {

            .form-control {
                max-width: 120px;
            }
        }
    }

}