@use '../../variables'as c;
.tw-modal.fb-pages-modal {
    .modal-icon {
        color: #1778F2;
    }

    .modal-sub-title {
        color: #646a73;
        font-size: 14px;
    }

    .page-elem {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 8px;
        font-size: 14px;

        &.is-selected {
            border: 1px solid #9686f2;
        }
    }
}