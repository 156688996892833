@use '../../../variables'as c;

.color-wrap {
    position: relative;
    display: flex;

    .swatch {
        display: inline-block;
        cursor: pointer;
        position: relative;

        .swatch-color {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, .1)
        }

        .swatch-overlay {
            position: absolute;
            width: 22px;
            height: 22px;
            background: #fff;
            display: flex;
            border-radius: 14px;
            font-size: 11px;
            color: #646a73;
            cursor: pointer;
            right: -8px;
            bottom: -8px;
            border: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    &.small-wrap {
        .swatch-color {
            width: 24px;
            height: 24px;
        }
    }


    .color-popover {
        position: absolute;
        z-index: 2;

        .popover-cover {
            position: fixed;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
        }
    }

    &.align-right {
        .color-popover {
            right: 0px;
            top: 28px;
        }
    }
}