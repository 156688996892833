.confirm-modal{
    button {
        min-width: 50px;
        .loader {
            display: none;
        }
    }
      
    button:disabled {
        :not(.confirm-input-missmatch) {
            .confirm-text {
                display: none;
            }
        
            .loader {
                display: block;
            }
        }
    }
}
