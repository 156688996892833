@use '../../variables'as c;

.chat-email-msg {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    margin: 12px 0px;
    color: rgb(37, 48, 80);
    min-width: 65px;
    font-size: 12px;
    font-size: 12px;

    box-shadow: 0px 2px 4px rgb(136 151 183 / 8%);
    border-radius: 4px;
    border: 1px solid #dae1ef66;

    width: 80%;
    min-width: 65px;
    margin-right: auto;
    border-radius: 8px 20px 20px 20px;

    .support-name {
        //color: c.$text-primary-color;
        color: c.$text-extra-light-color;
        font-weight: 500;
        position: absolute;
        top: -16px;
        right: 6px;
        user-select: none;
        font-size: 10px;
        min-width: 75px;
        line-height: 15px;
        white-space: nowrap !important;
        text-align: right;
    }

    &.msg-preview{
        cursor: pointer;
    }

    &.is-support-msg {
        border-radius: 20px 8px 20px 20px;
        margin-left: auto;
        margin-right: 0px;
    }
   
    .chat-email-msg__header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        cursor: pointer;
        padding: 12px;


        padding-bottom: 12px;

        .header-left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-grow: 1;

            .header-from {
                font-size: 12px;
                color: #42526e;
                color: #253050;
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: flex;
                width: 100%;

                .email-address {
                    font-weight: 400;
                    color: #6a76b5;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: flex;
                    width: 100%;
                }
            }

            .header-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                font-size: 12px;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                position: relative;
                overflow: hidden;

                .chat-email-msg__subject {
                    color: #42526e;
                    color: #253050;
                    font-weight: 500;
                    margin-right: 6px;
                }
                .chat-email-msg__body {
                    font-weight: 400;
                    min-height: 0px;
                    width: 100%;
                    color: #6a76b5;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    position: relative;
                    min-height: 18px;
                    overflow-x: auto;

                    img {
                        max-width: 100%;
                        min-width: auto;
                        min-height: auto;
                        height: auto;
                    }
                }
            }
        }

        .time-wrap {
            min-width: 0;
            flex-shrink: 0;
            margin-left: auto;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            font-weight: 400;
            color: #6a76b5;

            font-size: 10px;
            .time {
                font-size: 12px;
                font-weight: 400;
                color: #9e9e9e;
            }
        }
    }

    .view-email {
        color: #6a76b5;
        text-decoration: underline;
    }

    .chat-email-body{
        width: 100%;

        padding: 12px;
        padding-top: 18px;

        border-top: 1px solid #dae1ef66;
        background: #fff;
        min-height: 22px;
        overflow: hidden;
        border-radius: 6px;

        a {
            color: #03a9f4;
            text-decoration: underline;
        }

        &:last-child {
            border-radius: 0px 0px 20px 20px;
        }
    }

    .header-attachments, .body-attachments {
        display: flex;
        width: 100%;
        display: flex;
        border-top: 1px solid #f0f3f8;
        margin-top: 6px;
        padding-top: 6px;
    }

    .body-attachments {
        padding: 6px 12px;
    }

    .loading-body-ph,
    .empty-body-ph {
        text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #dcdddf;
    }

    .email-attachment-wrap {
        display: flex;
        padding: 3px 9px;
        margin: 2px 4px;

        border: 1px solid #dae1ef66;
        border-radius: 8px;
        cursor: pointer;
        user-select: none;

        &:hover {
            background: #fdfdff;
            text-decoration: none;
        }
        
        &:first-child {
            margin-left: 0px;
        }

        .email-attachment-icon {
            font-size: 10px;
            margin-right: 4px;
            display: grid;
            place-items: center;
            color: #6a76b5;
        }

        .email-attachment-name {
            max-width: 182px;
            min-width: 20px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .email-attachment-size {
            font-size: 10px;
            padding: 0px 2px;
            margin-left: 4px;
            color: #7d87be;
            display: grid;
            place-items: center;
        }

    }
}

 