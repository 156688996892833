@use './../../variables'as c;

.chat-details {
    display: flex;
    flex-direction: column;
    padding: 16px;
    color: c.$text-primary-color;

    .user-details {
        padding: 6px;
        display: flex;
        flex-direction: column;

        .visitor-icon {
            width: 30px;
            height: 30px;
            border-radius: 15px;
            background: linear-gradient(0deg, #DAE1EF, #DAE1EF), #C4C4C4;
            margin: auto 12px auto 0px;
            color: #2b364c;
            text-align: center;
            line-height: 30px;
            font-size: 12px;
            position: relative;
            flex-shrink: 0;

            .visitor-letter {
                text-transform: uppercase;

            }

            .is-online {
                position: absolute;
                background-color: #45c4a0;
                right: 1px;
                top: 1px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
            }

            .email-icon {
                position: absolute;
                width: 14px;
                height: 14px;
                right: -3px;
                bottom: -3px;
            }
            
            .insta-icon {
                position: absolute;
                width: 14px;
                height: 14px;
                right: -3px;
                bottom: -3px;
            }

            .fb-icon {
                position: absolute;
                width: 18px;
                height: 18px;
                right: -4px;
                bottom: -4px;
            }
        }

        .visitor-name {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.secondary {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
            }
        }

        .location {
            margin-top: 14px;
            font-size: 14px;

            i {
                color: #9686f2;
            }

            .block-visitor {
                cursor: pointer;

                i {
                    color: #505050;
                }

                &.is-blocked {
                    i {
                        color: c.$error-color;
                    }

                }
            }
        }

        .connection-status {
            background: rgba(80, 80, 80, 0.1);
            color: rgb(80, 80, 80);
            padding: 0px 8px;
            border-radius: 6px;
            font-size: 14px;
            line-height: 22px;
            height: 22px;
            margin-top: auto;
            margin-bottom: auto;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:before {
                content: "\A";
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: rgb(80, 80, 80);
                display: inline-block;
                margin: 2px 4px 2px 0px;
            }

            &.status-live {
                background: rgba(142, 194, 0, 0.1);
                color: rgb(142, 194, 0);

                &:before {
                    background: rgb(142, 194, 0);
                }
            }
        }

    }


    .visitor-info {
        font-size: 14px;

        .title {
            color: c.$text-light-color;
            font-weight: 500;
            font-size: 14px;
            margin: 0;
            display: flex;

            .edit-btn {
                padding: 0px 6px;
                cursor: pointer;
            }

            .saving {
                display: flex;
                align-items: center;

                height: 20px;
                background-color: #f5f6f8;
                padding: 0px 6px;
                border-radius: 4px;
                font-size: 12px;
            }
        }

        .details {
            margin: 0;
            overflow: hidden;
            white-space: nowrap;

            &.visited-url {
                color: #00a9f4;
                text-overflow: ellipsis;
                text-decoration: underline;

                a {
                    color: #00a9f4;
                }
            }
        }

        .more-details {
            margin: 0;
            color: #00a9f4;
            cursor: pointer;
            margin-top: 6px;
        }

        textarea {
            color: c.$text-primary-color;
            width: 100%;
            height: 180px;
            margin-top: 6px;
            padding: 6px;
            resize: none;
            border: 1px solid #DAE1EF;
            border-radius: 6px;
            outline: none;
        }
    }



}

.int-wrap {
    .int-switch-wrap {
        padding: 4px 12px;
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #dae1ef;
        position: sticky;
        top: 0;
        background: #fff;
        align-items: center;
        z-index: 10;

        .int-switch-back {
            user-select: none;
            padding: 0px 6px;
            cursor: pointer;
            border-radius: 4px;
            font-size: 14px;
            color: #42526e;

            &:hover {
                background-color: #e5e6e8;
            }
        }

        .int-switch {
            user-select: none;

            height: 24px;
            width: 24px;
            display: flex;
            cursor: pointer;
            border-radius: 4px;
            position: relative;

            &:hover {
                background-color: #d2d2d29b;
            }

            &.active {
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    right: 0;
                    height: 2px;
                    background-color: #42526e;
                }
            }
        }
    }
}

.mb-main-area {
    .chat-details {
        &.mini {
            padding: 0;

            .user-details {
                padding-bottom: 0;
                padding: 12px 8px;
                border-bottom: none;

                i {
                    font-size: 12px;
                    line-height: 32px;
                }

                .location {
                    margin-top: 6px;
                }
            }

            .dropdown-w-bg {
                .dropdown {
                    a.form-control {
                        border: none;
                        background: #e7e3f3;
                        color: #9686f2;
                        padding: 0px 10px;
                        height: 24px;
                        border-radius: 12px;
                        font-size: 14px;
                        line-height: 24px;

                        i {
                            line-height: 24px;
                        }
                    }

                    .dropdown-menu {
                        border: none;
                        border-radius: 0px;
                        overflow: hidden;

                        .cancel-dd {
                            line-height: 48px;
                        }

                        ul {
                            max-height: calc(100% - 38px);
                        }
                    }
                }
            }
        }
    }
}