@use '../../variables'as c;

.analytics-page {
    width: 100%;
    padding: 24px 24px 24px 12px;

    .tw-popover-trigger {
        color: c.$text-light-color;
        float: right;
    }

    .title-row {
        margin-bottom: 24px;
        padding: 12px 24px;
        font-size: 24px;
        font-weight: 600;
        z-index: 2;
        overflow: unset;
    }

    .analytics-page-data {
        overflow: auto;
        padding-bottom: 24px;

        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        margin: 0px -8px;

        .multi-part-row {
            display: flex;
            flex-wrap: wrap;

            &>div {
                flex: 1;
            }
        }

        .analytics-title {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 16px;
        }

        .analytics-minor-title {
            color: c.$text-light-color;
        }

        .content-card {
            flex-direction: column;
            padding: 12px 24px;
            margin: 8px;

            &.large {
                flex: 3;
            }
        }

        .multi-part div {
            padding: 2px 24px;

            &:not(:last-child) {
                border-right: 1px solid #dee2e6;
            }
        }

        .single-metric {
            font-size: 42px;
            font-size: 54px;
            text-align: center;
            font-weight: 300;
            color: #8884d8;

            .sub-metric {
                color: #42526e;
                font-size: 14px;

                .accent {
                    color: #8884d8;
                }
            }
        }
    }

    .tw-chart-tooltip {
        background-color: #fff;
        border-radius: 6px;
        padding: 8px 12px;
        box-shadow: 0px 2px 4px rgba(136, 151, 183, 0.55);
        border: none;
        outline: none;

        p {
            margin: 0;

            .label-item {
                font-weight: 500;
            }
        }
    }

    .loading-stats {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        background: #fff;
        font-size: 22px;
        color: c.$text-light-color;
    }

    .source-filter-wrap {
        padding: 0px 12px;
        align-items: center;

        .source-filter {
            cursor: pointer;
            font-weight: 400;
            color: #42526e;
            font-size: 16px;
            padding: 0px 3px;

            &:hover {
                text-decoration: underline;
            }
    
            &.active {
                color: #8884d8;
            }
        }
    }

    table {
        width: 100%;
        table-layout: fixed;

        th {
            font-weight: 600;
            color: rgba(0, 0, 0, .54);
        }

        tr {
            height: 48px;
            border-bottom: 1px solid rgba(0, 0, 0, .12);

            td {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}