.periodpicker {
    .rdrDateDisplayWrapper {
        background-color: #fff;
    }

    .apply-range {
        position: absolute;
        bottom: 12px;
        left: 12px;
    }
}