@use './../../variables'as c;

.chat-list {
  border-right: 6px solid #eaecf2;
  border-radius: 12px 0px 0px 12px;
  //transition: all 0.2s ease-out;

  &.search-mode {
    width: 420px;
  }

  .chat-list-filters-wrap {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    padding-bottom: 0px;

    .search-toggle-wrap {
      .search-toggle {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 5px 7px 5px 17px;
        background-color: #dcdddf;
        font-size: 14px;
        color: #8a8d93;
        border-radius: 12px;
        margin-bottom: 12px;
        border: 1px solid transparent;
        user-select: none;

        &.active {
          border-color: #9686f2;
          box-shadow: #302a4ad4 0px 0px 2px 0px;
          background-color: #fff;
          color: #9686f2;
        }

        .search-disp {
          width: calc(100% - 25px);
          overflow: hidden;
        }
      }

      .search-popout-wrap {
        cursor: default;
        position: absolute;
        width: 320px;
        // background: rgba(255, 255, 255, 0.337);
        background-color: #fff;
        top: 6px;
        left: 250px;
        padding: 6px 6px;
        border-radius: 12px;
        box-shadow: rgba(141, 145, 156, 0.33) 0px 4px 24px;
        font-size: 14px;
        z-index: 1001;

        .input-wrap {
          display: flex;
          padding: 6px 18px;
          width: 100%;
          border-radius: 12px;
          background-color: #f3f3f3;
          color: #8a8d93;
          align-items: center;

          input {
            border: none;
            outline: none;
            flex: 1;
            background-color: #f3f3f3;
            padding: 0;

            &::placeholder {
              color: #344563;
            }
          }

        }

        .info-txt {
          font-size: 12px;
          margin-top: 2px;
          color: #94a3c1;
          margin-left: 12px;
          user-select: none;
        }

        .sml-btn {
          outline: none;
          border: none;
          background: #9686f2;
          color: #fff;
          padding: 2px 12px;
          border-radius: 8px;

          &.sml-secondary {
            background: #adb2bc;
          }

          &:disabled {
            background: #302a4a20;
          }
        }
      }

      .tags-select-wrap {
        .tag-input-wrap {
          width: 100%;
          margin: 4px 0px;

          input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #f5f6f8;
            outline: none;
            flex: 1;
            padding: 0;
          }
        }
      }

      .select-one-wrap {
        .selectable-tag {
          background: #f5f6f8;
          margin: 2px;
          color: #8a8d93;
          padding: 0px 8px;
          border-radius: 6px;
          cursor: pointer;
          user-select: none;

          &:hover {
            background: adjust-color(#f5f6f8, $lightness: -5%);
          }

          &.active {
            background-color: #9686f2;
            color: #fff;
          }
        }
      }
    }

    .chat-list-sources {
      user-select: none;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      border-bottom: 1px solid #dae1ef;
      padding-bottom: 4px;
      margin-bottom: 4px;
      color: #5e70a2;


      .source-filter {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 4px 4px;
        border-radius: 12px;
        color: #5e70a2;
        position: relative;

        &:hover {
            color: #9686f2;
        }

        &.active {
            color: #9686f2;
            font-weight: 600;
        }

        &.notificationbadge {
          &::after{
            content: '';
            position: absolute;
            top: 2px;
            right: -4px;
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: #E64747;
            margin-left: 4px;
          }
        }
      }
    }

    .chat-list-presets {
      display: flex;
      flex-direction: column;
      user-select: none;

      .chat-filter-elem {
        cursor: pointer;
        display: flex;
        padding: 4px 4px;
        font-size: 13px;

        .filter-name {
          color: c.$text-light-color;
        }

        .filter-count {
          margin-left: auto;
          padding: 0px 10px;
          border-radius: 12px;
          background-color: #dcdddf;
          color: #8a8d93;
          position: relative;

          &.unreads::before {
            content: '';
            position: absolute;
            right: 2px;
            top: -1px;
            width: 8px;
            height: 8px;
            background-color: c.$error-color;
            border-radius: 4px;
            box-shadow: 0px 0px 0px 1px #F5F6F8;
          }
        }

        &:hover {
          .filter-name {
            color: #9686f2;
          }

          .filter-count {
            background-color: #9686f23d;
          }
        }

        &.alert-new {
          .filter-count {
            background-color: c.$error-color;
            color: #fff;
          }
        }

        &.active {
          .filter-name {
            color: #9686f2;
            font-weight: 600;
          }

          .filter-count {
            background-color: #9686f2;
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }

  }


  .red-dot {
    position: absolute;
    padding: 1px 4px;
    height: 12px;
    min-width: 12px;
    border-radius: 6px;
    right: 0px;
    top: -2px;
    background-color: c.$error-color;
    font-size: 10px;
    line-height: 10px;
    color: #fff;
  }

  .chat-list-elem-upper {
    display: flex;
  }

  .chat-list-elem-lower {}

  .tag-new {
    margin-left: 8px;
    color: #007bff;
    font-size: 12px;
    font-weight: 400;
    padding: 0px 6px;
    border-radius: 6px;
    line-height: 18px;
    height: 18px;
    background-color: #007bff20;
  }

  .writing-wrap {
    position: absolute;
    left: 12px;
    top: 12px;
  }

  .writing {
    position: relative;
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: #686869;
    color: #686869;
    animation: dotElastic 1s infinite linear;
  }

  .writing::before,
  .writing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .writing::before {
    left: -6px;
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: #686869;
    color: #686869;
    animation: dotElasticBefore 1s infinite linear;
  }

  .writing::after {
    left: 6px;
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: #686869;
    color: #686869;
    animation: dotElasticAfter 1s infinite linear;
  }

  figure {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 42px;

    img {
      height: 180px;
      object-fit: contain;
    }

    figcaption {
      margin: auto;
      margin-top: 18px;
      color: #302A4A;
      max-width: 380px;
      font-size: 14px;
      font-weight: 600;

      .small-title {
        font-weight: 600;
      }
    }
  }

  @keyframes dotElasticBefore {
    0% {
      transform: scale(1, 1);
    }

    25% {
      transform: scale(1, 1.3);
    }

    50% {
      transform: scale(1, 0.77);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes dotElastic {
    0% {
      transform: scale(1, 1);
    }

    25% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1, 1.3);
    }

    75% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes dotElasticAfter {
    0% {
      transform: scale(1, 1);
    }

    25% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1, 0.77);
    }

    75% {
      transform: scale(1, 1.3);
    }

    100% {
      transform: scale(1, 1);
    }
  }

}