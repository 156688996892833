@use '../../../variables'as c;

.editable-detail {
    margin-top: 4px;

    &.active {
        .detail-wrap {
            border: 1px solid #ECF3F9;
            padding: 4px 12px;
        }
    }

    .detail-wrap {
        display: flex;
        width: 100%;
        padding: 4px 0px;
        line-height: 16px;
        border-radius: 6px;
        border: 1px solid transparent;
        transition-timing-function: ease-in;
        transition: padding 0.15s;

        input {
            outline: none;
            border: none;
            background: transparent;
            font-size: 14px;
            color: c.$text-primary-color;
            flex: 1;
            padding: 0;
        }

        i {
            color: #b5bfd3;
            padding: 4px 4px;
            cursor: pointer;

            &:hover {
                border-radius: 3px;
                background-color: #ECF3F9;
            }
        }
    }
}