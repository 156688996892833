@use '../../variables'as c;


.image-preview-wrap {
    position: relative;
    display: inline-flex;

    .remove-image {
        position: absolute;
        width: 24px;
        height: 25px;
        background: #fff;
        display: flex;
        border-radius: 14px;
        font-size: 12px;
        color: #646a73;
        cursor: pointer;
        right: -6px;
        top: -6px;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .logo-preview {
        cursor: pointer;
        width: 62px;
        height: 62px;
        padding: 4px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);

        object-fit: cover;
    }

    &.image-wide {
        display: flex;
        margin: auto;

        .logo-preview {
            cursor: pointer;
            width: 122px;
            height: 122px;

            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.1);

            object-fit: cover;
        }
    }
}