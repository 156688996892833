.field-with-copy {
    display: flex;
    cursor: pointer;
    align-items: center;
    color: #42516e;
    font-size: 12px;

    .field-with-copy__field {
        background: #f5f6f8;
        padding: 0px 6px;
        border-radius: 4px;
        color: #5e6b83;
        font-family: monospace;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 275px;
    }

    &:hover {
       i {
            color: #45c4a0;
        }
    }
}