@use '../../variables'as c;

.media-modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .media-wrap {

        img,
        video {
            max-height: 80vh;
            max-width: 70vh;
        }

        .media-tools {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            display: flex;
            padding: 0px 18px;
            pointer-events: none;

            .tool-opt {
                pointer-events: all;
                cursor: pointer;
                font-size: 26px;
                color: #fff;
                padding: 12px;
            }
        }
    }
}