@use '../../variables'as c;

.popup-list-page {
    width: 100%;
    padding: 24px 24px 24px 12px;

    .title-row {
        margin-bottom: 24px;
        padding: 12px 24px;
        font-size: 24px;
        font-weight: 600;
        z-index: 2;
        overflow: unset;
    }

    .popup-list-body {
        min-height: 120px;
        width: 100%;
        padding: 12px 24px;


        table.popup-list {
            width: 100%;

            th {
                font-size: 16px;
                font-weight: 500;
                color: c.$text-light-color;
            }

            th.shrink,
            td.shrink {
                white-space: nowrap;
                width: 1px;
            }

            td,
            th {
                padding: 12px 16px;
                border-bottom: 1px solid #f5f6f8;
            }

            th:last-of-type,
            td:last-of-type {
                //   display: flex;
                //    justify-content: flex-end;
            }
        }
    }
}

.popup-list-page-welcome {
    display: flex;
    width: 100%;
    padding: 24px 24px 24px 12px;
    font-size: 16px;

    .welcome-popups {
        margin: auto;
        margin-top: 42px;
        display: flex;
        padding: 34px 48px;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0px 4px 14px rgba(136, 151, 183, 0.15);

        .title {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 42px;

            .sub-title {
                font-weight: 400;
                font-size: 16px;
                color: c.$text-light-color;
            }

            .secondary {
                color: #7b5ed0;
            }
        }

        .feature-list {
            margin-bottom: 42px;
        }

        .sub-text {
            color: c.$text-light-color;
            margin-bottom: 42px;
        }

        .illustration {
            width: 280px;
            object-fit: contain;
            object-position: bottom right;
        }

        .new-booster-cta {
            line-height: 40px;
            padding: 2px 28px;
            color: #fff;
            border-radius: 20px;
            border-top-left-radius: 4px;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.025em;
            background-color: #6453C5;
            text-decoration: none;
        }
    }
}