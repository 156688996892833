@use '../../variables'as c;

.popup-page {
    width: 100%;
    height: 100%;
    padding: 24px 24px 24px 12px;

    .title-row {
        display: flex;
        margin-bottom: 24px;
        padding: 12px 0px;
        font-size: 24px;
        font-weight: 600;
        z-index: 2;
        overflow: unset;
    }

    .sub-title-row {
        margin: 24px auto;
        padding: 12px 24px;
        font-size: 18px;
        font-weight: 500;
        width: fit-content;
        z-index: 2;
        overflow: unset;
        color: c.$text-light-color;
    }

    .popup-horizontal-toolbar {
        //  height: 42px;
        padding: 12px 16px;

        >div {
            flex: 1;
        }
    }

    .popup-toolbar {
        width: 340px;
        padding: 12px 24px;
        overflow: auto;

        .type-description {
            color: c.$text-light-color;
        }

        textarea {
            min-height: 120px;
            max-height: 210px;
        }

        .dropdown {
            button {
                width: 100%;
            }
        }
    }

    .preview-container {
        flex: 1;

        background: #F5F6F8;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .platform-switch {
            cursor: pointer;
            color: #646a73;

            &:hover {
                color: #0e1111;

            }

            &.is-selected {
                color: #9686f2;
            }
        }

        .preview-popup {
            margin: auto;
            background: #fff;

        }
    }

    .patterns-wrap {
        flex-wrap: wrap;
        justify-content: center;

        .pattern-select-wrap {
            width: 84px;
            height: 48px;
            border: 1px solid #eaf1ff;
            border-radius: 4px;
            margin-bottom: 6px;
            margin-right: 12px;
            display: flex;
            color: #646a73;
            cursor: pointer;

            position: relative;

            .text-overlay {
                display: flex;
                position: absolute;
                inset: 0;
            }

            .pattern-preview {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        input:checked+.pattern-select-wrap {
            border-color: #9686f2;
        }
    }

    .intents-wrap {
        display: flex;
        gap: 24px;

        //flex-wrap: wrap;
        justify-content: center;

        .intent-group-card,
        .intent-card {
            position: relative;
            overflow: hidden;
            flex: 1;
            border: 1px solid #fff;
            cursor: pointer;

            .coming-soon {
                position: absolute;
                inset: -1px;
                background: #ffffffc9;
                display: flex;

                .ph-text {
                    margin: auto;
                    color: c.$text-light-color;
                    font-size: 28px;
                    font-weight: 600;
                }
            }
        }

        .intent-group-card {
            padding: 16px 24px;
            min-width: 375px;
            max-width: 575px;
            cursor: pointer;

            &.selected {
                border-color: #9686f2;

                .ico-wrap {
                    color: #9686f2;
                }
            }

            .ico-wrap {
                padding: 12px;
                font-size: 48px;
                width: 72px;
                display: flex;
                align-items: center;
                color: c.$text-light-color;
            }

            .descriptions {
                flex: 1;
                margin-left: 18px;

                .title {
                    font-weight: 600;
                    font-size: 18px;
                    margin-bottom: 6px;

                    i {
                        color: #4BD1A0;
                    }
                }

                .sub-text {
                    font-size: 14px;
                    color: c.$text-light-color;
                }
            }
        }

        .intent-card {
            padding: 16px 24px;
            min-width: 125px;
            max-width: 500px;

            &:hover {
                border-color: #9686f2;
            }

            .descriptions {
                flex: 1;

                .title {
                    font-weight: 600;
                    font-size: 18px;
                    margin-bottom: 6px;

                    i {
                        color: #4BD1A0;
                        margin-right: 12px;
                    }
                }

                .sub-text {
                    font-size: 14px;
                    color: c.$text-light-color;
                    font-size: 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .coming-soon {
                .ph-text {
                    font-size: 22px;
                }
            }
        }
    }
}

.popup-template {
    font-family: 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}