@use '../../variables'as c;

.landing-mob-view {
    background-color: #f5f6f8;
    display: flex;
    flex-direction: column;

    .top-part {
        background-color: #E4DBD2;
        padding: 24px 48px;
        min-height: 45%;
        font-size: 16px;
        font-weight: 500;
        color: c.$text-primary-color;
        position: relative;
        // text-align: center;

        .logo {
            margin: 32px 48px;
            max-width: 180px;
        }

        .illustration {
            width: 185px;
            margin: auto;
            margin: auto;
            right: 0px;
            bottom: -45px;
            position: absolute;
        }
    }

    .store-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 66px;
        color: c.$text-light-color;
    }

    .store-btn {
        display: flex;
        height: 82px;
        cursor: pointer;

        svg {
            width: 160px;
            height: 82px;
        }
    }

    svg#gStore {
        .st0 {
            fill-rule: evenodd;
            clip-rule: evenodd;
        }

        .st1 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #A6A6A6;
        }

        .st2 {
            fill: #FFFFFF;
        }

        .st3 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #FFFFFF;
        }

        .st4 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: url(#SVGID_1_);
        }

        .st5 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: url(#SVGID_2_);
        }

        .st6 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: url(#SVGID_3_);
        }

        .st7 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: url(#SVGID_4_);
        }

        .st8 {
            opacity: 0.2;
            fill-rule: evenodd;
            clip-rule: evenodd;
            enable-background: new;
        }

        .st9 {
            opacity: 0.12;
            fill-rule: evenodd;
            clip-rule: evenodd;
            enable-background: new;
        }

        .st10 {
            opacity: 0.25;
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #FFFFFF;
            enable-background: new;
        }
    }
}